@import '../../scss/config';

.map-modal-wrapper {
  .map-modal {
    top: 5% !important;
    bottom: 5% !important;

    .ant-modal-content {
      padding-top: 0;
      .ant-modal-close {
        .anticon {
          svg {
            color: $main-text-color;
          }
        }
      }
    }

    .ant-modal-header {
      background-image: none;
      .ant-modal-title {
        .modal-title {
          color: $main-text-color;
        }
      }
    }

    .ant-modal-body {
      padding: 14px !important;
      @include mediaLg {
        padding: 24px;
      }
    }
  }
}
