@import '../../../scss/config';

.store-card {
  background-color: #fff;
  border: 1px solid #eaebed;
  padding: 18px 22px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(171, 171, 171, 0.05);
  .store-card-content {
    display: grid;
    @include mediaLg {
      grid-template-columns: auto 1fr;
    }
    gap: 18px;
    align-items: center;
  }
  .store-logo {
    width: max-content;
    .store-img {
      min-width: 182px;
      min-height: 182px;
      position: relative;
      display: grid;
      border-radius: 8px;
      place-items: center;
      background-image: linear-gradient(
        136.01deg,
        rgba(157, 78, 221, 0.9) 19.34%,
        rgba(251, 46, 134, 0.9) 95.03%
      );

      // &::after {
      //   content: '';
      //   position: absolute;
      //   top: 5%;
      //   left: -5%;
      //   right: 5%;
      //   bottom: -5%;
      //   // border-radius: 50%;
      //   background-color: #290a44;
      // }
      img,
      svg {
        height: 172px !important;
        width: 172px !important;
        object-fit: cover;
        object-position: center center;
        z-index: 2;
        position: relative;
        transition: all 0.1s ease;
        border-radius: 6px;
      }
    }

    .ant-skeleton-image {
      background-color: #fff;
    }
  }

  .name-actions-wrap {
    .name-wrap {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .name-status-income {
        .name-status {
          display: flex;
          gap: 12px;
          align-items: center;
          margin-bottom: 8px;
          .name {
            color: #290a44;
            font-size: 20px;
            font-weight: bold;
          }
          .reject-reason-wrap {
            display: flex;
            button {
              position: relative;
              z-index: 2;
              cursor: pointer;
              color: #5855f8;
              border: 0;
              outline: 0;
              background-color: transparent;
              padding: 2px 16px;
              text-decoration: underline;
            }
          }
          .status {
            display: flex;
            gap: 4px;
            align-items: center;
            &.active {
              color: #25d366;
            }
            &.pinding {
              color: #f9a000;
            }
            &.rejected {
              color: #ed0006;
            }
          }
        }

        .total-withdraw {
          display: flex;
          align-items: center;
          gap: 18px;
          margin-bottom: 8px;
          .total-wrap {
            span {
              &:first-of-type {
                color: #aaaaaa;
              }
              &:last-of-type {
                color: $main-app-color;
              }
            }
          }

          .withdraw {
            display: flex;
            align-items: center;
            gap: 4px;
            color: $main-app-color;
            border: 1px solid $main-app-color;
            padding: 2px 22px;
            border-radius: 4px;
            cursor: pointer;
            svg {
              path {
                fill: $main-app-color;
              }
            }
          }
        }
      }
    }

    .actions-wrap {
      display: flex;
      gap: 8px;
      flex-wrap: wrap;
      margin-top: 22px;

      .action-btn {
        background: #ffffff;
        border: 1px solid #eaebed;
        box-shadow: 0px 4px 8px rgba(218, 218, 218, 0.1);
        border-radius: 4px;
        color: #6d6d6d;
        padding: 6px 24px;
        display: flex;
        gap: 4px;
        align-items: center;
      }
    }
  }
  .more-btns-wrap {
    position: relative;
    z-index: 2;
    pointer-events: all;
    display: flex;
    gap: 4px;
    align-items: flex-start;
    button {
      border: 0;
      outline: 0;
      background-color: transparent;
      cursor: pointer;
    }
  }
  .fee-wrap {
    color: #c4c4c4;
    strong {
      color: $main-app-color;
    }
  }

  &.disabled {
    position: relative;
    overflow: hidden;
    opacity: 0.8;
    // background-color: rgba($main-app-color, 0.04);
    &::after {
      cursor: not-allowed;
      background-image: url('../../../assets/imgs/bgs/architect.svg');
      opacity: 0.1;
      background-repeat: repeat;
      background-position: center;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
