@import '../../../scss/config';

.create-store-step-one {
  .names-wrapper,
  desc-wrapper {
    display: grid;
    gap: 18px;
    @include mediaLg {
      grid-template-columns: 1fr 1fr;
    }
  }

  .images-wrap,
  .cover-logo-wrap {
    margin: 22px 0;
  }

  .cover-logo-wrap {
    display: grid;
    gap: 22px;
    @include mediaLg {
      grid-template-columns: auto 1fr;
    }
  }

  .logo-dropzone-wrap {
    justify-self: center;
    order: 1;
    @include mediaLg {
      order: 0;
    }
    .file-input-label {
      text-align: center;
    }
    .dropZone {
      border-radius: 50%;
      width: 142px;
      height: 142px;
    }

    .default-dropzone-img {
      width: 134px;
      height: 134px;
      border-radius: 50%;
    }
    .delete-img-btn {
      border-radius: 50%;
    }
  }
}
