@import '../../../scss/config';

.new-employee-form {
  .form-text {
    border-bottom: 2px solid rgba(196, 196, 196, 0.6);
    padding-bottom: 18px;
    margin-bottom: 32px;

    .form-title {
      color: #290a44;
      font-size: 22px;
      margin-bottom: 8px;
    }
    .form-desc {
      color: #6d6d6d;
      line-height: 2;
    }
  }

  .search-email-form {
    // max-width: 800px;
    margin-bottom: 22px;
    label {
      display: flex;
      display: grid;
      grid-template-columns: auto 1fr;
      align-items: center;
      background-color: #fff;
      border: 1px solid #c4c4c4;
      padding: 4px 22px;
      border-radius: 4px;
      gap: 12px;
    }
    input {
      background-color: transparent;
      border: none !important;
      outline: none !important;
      color: $main-text-color;
      height: 38px;
      width: 100%;

      &::placeholder {
        color: $main-text-color;
      }
    }
    .icon-wrap {
      pointer-events: none;
      display: grid;
      place-items: center;
    }
  }

  .roles-wrap {
    .roles-title {
      color: #290a44;
      margin-bottom: 8px;
      font-size: 18px;
    }
    .roles-checkboxs {
      display: flex;
      flex-wrap: wrap;
      gap: 32px;
      label {
        color: #aaa;
        font-size: 15px;
      }
      .employees-roles {
        .ant-checkbox-group {
          display: grid;
          gap: 12px;
        }
      }
      .store-roles {
        .ant-checkbox-group {
          display: grid;
          grid-template-columns: auto auto;
          gap: 12px;
        }
      }
    }
  }

  .submit-btn {
    &:disabled {
      background-color: #f5f5f5;
      color: rgba(0, 0, 0, 0.25);
    }
  }
}

.search-emp-dropdown {
  .user-menu-item {
    padding: 0;
    .user-btn {
      background-color: transparent;
      border: 0;
      outline: 0;
      padding: 5px 12px;
      cursor: pointer;
    }
  }
}
