@import '../../../scss/config';

.store-statistics-product-card {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 12px;
  border: 1px solid #fdfbfe;
  box-shadow: 0px 4px 8px rgba(231, 230, 239, 0.5);
  &.rtl {
    box-shadow: 4px 0px 8px rgba(231, 230, 239, 0.5);
  }
  border-radius: 8px;
  padding: 12px 14px;
  border: 1px solid rgba(#000, 0.05);
  .product-img {
    background-color: #eee;
    min-width: 118px;
    svg {
      width: 32px !important;
    }

    img {
      border-radius: 8px;
      width: 118px;
      height: 100%;
      max-height: 118px;
      object-fit: cover;
      object-position: center center;
    }
  }

  .product-data {
    .bold-font {
      color: #290a44;
      max-width: 152px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-size: 14px;
    }
    .cat-catFilter {
      font-size: 13px;
      color: $main-app-color;
    }

    .stars-wrap {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 15px;
      line-height: 15px !important;
      svg {
        font-size: 15px;
      }

      .ant-rate-star:not(:last-child) {
        margin-right: 4px;
      }

      .ant-rate-rtl .ant-rate-star:not(:last-child) {
        margin-right: 0;
        margin-left: 4px;
      }
    }
    .sale-count-p {
      font-size: 14px;
    }
  }
}
