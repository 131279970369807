@import '../../scss/config';

.activate-user-page {
  min-height: 100vh;
  padding: 42px 0;
  display: grid;
  place-items: center;
  background-color: #fafafa;
  .logo-wrap {
    display: flex;
    margin: 22px auto;
    img {
      margin: auto;
    }
  }
  .modal-title {
    color: $main-app-color;
    font-size: 28px;
    text-align: center;
    font-weight: bold;
    margin-bottom: 12px;
  }
  .form-title {
    margin-bottom: 28px;
    text-align: center;
    color: #c4c4c4;
  }
  .title-form-wrap {
    background-color: #fff;
    width: fit-content;
    padding: 42px;
    margin: auto;
    border-radius: 12px;
  }
}
