@import '../../scss/config';

.general-products-drawer-filter-wrapper {
  .filter-btn {
    background-color: $main-app-color;
    color: #fff;
    height: auto;
    border: 0;
    outline: 0;
    padding: 8px 22px;
    border-radius: 4px;
  }
  @include mediaLg {
    display: none;
  }
}

.general-products-drawer {
  .ant-drawer-content-wrapper {
    width: 80%;
    max-width: 332px;
  }
  .general-products-filter {
    display: block;
    @include mediaLg {
      display: none;
    }
  }
}

.countries-cities-checkboxes {
  margin-top: 5px;
}

.cities-checkboxs-wrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 12px;
  margin-inline-start: 12px;
  margin-top: 6px;
}

.general-products-filter {
  display: none;
  @include mediaLg {
    overflow-y: auto;
    height: 90vh;
    display: block;
    width: 232px;
    overflow-y: auto;
    @include custom-scrollbar;
    background-color: #fafafa;
    transition: all 0.3s ease-out;
    &:hover {
      background-color: #b5b5b5;
    }

    &::-webkit-scrollbar {
      width: 6px;
      background-color: #fafafa;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
      margin: 5px 0;
    }

    &::-webkit-scrollbar-thumb {
      transition: all 0.3s ease-out;
      background-color: inherit;
    }

    .ant-form {
      background-color: #fafafa;
      padding: 8px;
    }
  }

  .general-products-filter-form {
    .form-body {
      .shared-filter-section {
        .check-all-checkbox {
          margin-bottom: 8px;
        }
        .section-title {
          color: #292c58;
          font-size: 18px;
          text-decoration: underline;
          margin-bottom: 12px;
        }
        .section-checkboxs-wrap {
          display: grid;
          .ant-checkbox-group {
            display: grid;
            gap: 8px;
          }
        }
      }
      .btns-section {
        padding-bottom: 22px;
        .btns-wrap {
          display: grid;
          justify-content: start;
          gap: 8px;
          a {
            background-color: transparent;
            border: 0;
            outline: 0;
            cursor: pointer !important;
            text-align: start;

            &.active-btn,
            &:hover {
              color: $secondary-app-color;
            }
            &.active-rate-btn {
              border: 1px solid rgba(#000, 0.1);
              border-radius: 4px;
            }
          }
          svg {
            cursor: pointer;
          }
        }
      }
    }
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: $secondary-app-color !important;
      border-color: $secondary-app-color !important;
    }
    .ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .ant-checkbox:hover .ant-checkbox-inner,
    .ant-checkbox-input:focus + .ant-checkbox-inner {
      border-color: $secondary-app-color !important;
    }
    .ant-checkbox-checked::after {
      border: 1px solid $secondary-app-color !important;
    }
    .ant-checkbox-wrapper-checked {
      & > span {
        color: $secondary-app-color !important;
      }
    }
  }
}
