@import '../../scss/config';

.blog-card {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid rgba(#000, 0.1);

  &.slider-blog-card {
    margin: 0 6px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }

  .blog-img {
    height: 222px;
    @include mediaXXlg {
      height: 272px;
    }
    img,
    svg {
      object-fit: cover;
      object-position: center;
      border-radius: 8px;
      width: 100%;
      height: 100%;
      // max-height: 222px;
      // @include mediaXXlg {
      //   max-height: 272px;
      // }
    }
  }

  .blog-data {
    padding: 8px 18px;
    .blog-cat {
      font-size: 14px;
      color: $secondary-app-color;
    }
    .blog-title {
      color: #4b0121;
      font-weight: bold;
      font-size: 14px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      display: block;
      @include mediaLg {
        font-size: 16px;
      }
    }
    .card-desc {
      color: #c4c4c4;
      line-height: 1.8;
      font-size: 14px;

      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .more-count {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: $secondary-app-color;
      margin-top: 12px;
      margin-bottom: 18px;
    }
  }
}
