@import '../../scss/config';

.general-products-page {
  background-color: #fafafa;
  padding-bottom: 52px;
  min-height: 100vh;
  .page-content-wrap {
    margin-top: 32px;
    display: grid;
    @include mediaLg {
      grid-template-columns: auto 1fr;
    }
    gap: 22px;
  }
}
