.discount-code-form {
  .form-body {
    background-color: #f8f2fd;
    border-radius: 4px;
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 8px;
    .ant-form-item {
      margin-bottom: 0 !important;
    }
    .discount-text-field {
      border: 0 !important;
      box-shadow: none !important;
      background-color: transparent !important;
      width: 100%;
      height: 40px !important;
    }
    .apply-btn {
      border-radius: 4px;
      height: 34px !important;
      align-self: center;
      margin: 0 4px;
    }

    .ant-form-item-explain {
      position: absolute;
      top: calc(100%);
    }
  }
}
