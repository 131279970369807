.status-wrap {
  .order-status {
    padding: 6px 18px;
    text-align: center;
    color: #fff;
    border-radius: 999px;
    font-size: 13px;
    &.all {
      background-color: #6d6d6d;
    }
    &.new {
      background-color: #f6bb4e;
    }
    &.accepted {
      background-color: #f9a000;
    }
    &.completed {
      background-color: #25d366;
    }
    &.canceledByUser,
    &.rejectedByStore {
      background-color: #ed0006;
    }
  }
}
