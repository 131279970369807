@import '../../../scss/config';

.shared-dashboard-page {
  .main-page-title {
    font-weight: bold;
    font-size: 18px;
    @include mediaLg {
      font-size: 22px;
    }
  }

  .title-arrow-wrap {
    // margin-bottom: 22px;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 8px;
    button {
      background-color: transparent;
      border: 0;
      cursor: pointer;
      padding: 4px;
      display: flex;
      align-items: center;

      img {
        width: 24px;
        height: auto;
      }
    }
  }
}

.merchent-dashboard-page {
  .dashboard-cards-wrapper {
    margin-top: 22px;
    display: grid;
    gap: 12px;
    grid-template-columns: 1fr 1fr;

    @include mediaLg {
      gap: 22px;
      grid-template-columns: repeat(4, 1fr);
    }
  }
}
