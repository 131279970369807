@import '../../scss/config';

.single-saloon-page {
  padding: 52px 0;

  .saloon-data {
    display: grid;
    // grid-template-columns: auto 1fr 0.8fr;
    grid-template-columns: auto 1fr;
    justify-content: space-between;
    gap: 32px;
    margin-bottom: 62px;

    .img-name-active {
      max-width: 318px;
      display: grid;
      .saloon-img {
        border-radius: 32px;
        background-image: linear-gradient(
          125deg,
          #9d4edd 0%,
          #fc69a8 78.12%,
          #fb2e86 100%
        );
        padding: 8px;
        width: fit-content;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;

        img,
        svg {
          border-radius: 24px;
          width: 140px;
          height: 140px;
          object-fit: cover;
          object-position: center center;
        }
      }
      .saloon-name {
        color: #290a44;
        font-size: 22px;
        text-align: center;
        max-width: 156px;
        margin-top: 5px;
      }
    }

    .saloon-desc,
    .contact-info {
      background-color: #fff;
      border: 1px solid #eaebed;
      box-shadow: 0px 4px 8px rgba(171, 171, 171, 0.05);
      border-radius: 8px;
      padding: 18px 22px;
      color: #aaaaaa;
      .label-p {
        border-bottom: 1px solid #c4c4c4;
        padding-bottom: 12px;
        margin-bottom: 12px;
        color: #290a44;
        font-size: 22px;
      }

      .location-wrap {
        display: grid;
        gap: 8px;
        .country-city,
        .addresss-wrap {
          display: flex;
          gap: 5px;
        }
      }

      svg {
        margin-top: 4px;
      }
    }
  }

  h3 {
    color: #290a44;
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 18px;
  }
  .saloons-wrap {
    display: grid;
    gap: 18px;
    @include mediaMd {
      grid-template-columns: repeat(2, 1fr);
    }
    @include mediaLg {
      grid-template-columns: repeat(3, 1fr);
    }

    .general-store-card {
      background: #ffffff;
      border: 1px solid #eaebed;
      box-shadow: 0px 4px 8px rgba(171, 171, 171, 0.05);
      border-radius: 8px;
      overflow: hidden;
      height: calc(100% - 56px);

      .cover-img-wrap {
        height: 152px;
        overflow: hidden;
        .store-cover {
          height: 152px;
          overflow: hidden;
          img,
          svg {
            width: 100%;
            height: 152px;
            object-fit: cover;
            object-position: top center;
            box-shadow: 0px 4px 8px rgba(171, 171, 171, 0.2);
            border-radius: 8px;
          }
        }
      }

      .store-img {
        background-image: linear-gradient(
          125deg,
          #9d4edd 0%,
          #fc69a8 78.12%,
          #fb2e86 100%
        );
        width: 108px;
        height: 108px;
        border-radius: 50%;
        // margin: -56px auto 0 auto;
        margin: auto;
        transform: translateY(-56px);
        position: relative;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        svg,
        img {
          background-color: #fff;
          width: 106px;
          height: 106px;
          border-radius: 50%;
          object-fit: cover;
          object-position: center center;
          box-shadow: 0px 4px 8px rgba(171, 171, 171, 0.2);
        }
      }

      .tt-wrap {
        padding: 18px 22px;
        text-align: center;
        transform: translateY(-56px);
      }
      .store-name {
        text-align: center;
        color: #290a44;
        font-size: 22px;
        font-weight: bold;
        text-transform: capitalize;
        margin-top: 8px;
      }
      .store-location,
      .status {
        color: #aaaaaa;
        display: flex;
        gap: 5px;
        justify-content: center;
        svg {
          margin-top: 4px;
        }
      }
      .desc {
        color: #aaa;
        font-size: 15px;
        line-height: 1.8;
        margin-top: 12px;
      }
    }
  }
}
