@import '../../scss/config';

.user-fav-page {
  .breadcrumb-title {
    margin-bottom: 32px;
    .title-counter-wrapper {
      display: flex;
      gap: 8px;
      align-items: center;
      margin-top: 8px;
      margin-bottom: 12px;
      h2 {
        color: $secondary-app-color;
        padding: 0;
      }
      .counter-p {
        color: #290a44;
      }
    }
  }
  .products-wrap {
    border-radius: 12px;
    display: grid;
    margin: 42px 0;
    grid-template-columns: repeat(auto-fill, minmax(272px, 282px));
    gap: 18px;
    &.grid {
      grid-template-columns: repeat(auto-fill, minmax(292px, 1fr));
    }
    &.row {
      grid-template-columns: 1fr;
    }
  }
  .saloons-pagination {
    padding: 0 42px;
  }

  .view-as-sort-wrapper {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    justify-content: start;
    gap: 32px;

    .sort-filter-wrapper,
    .custom-view-as-wrapper {
      display: flex;
      gap: 8px;
      align-items: center;
    }
  }
}
