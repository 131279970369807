@import '../../scss/config';

.signup-page {
  .signup-form-section {
    display: grid;
    justify-items: center;
    max-width: 532px;
    margin: auto;
    margin-top: 42px;
  }
  .logo {
    margin-bottom: 32px;
    img {
      width: 222px;
    }
  }

  .btns-types-wrap {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
    z-index: 1;
    a {
      text-align: center;
      background-color: #fff;
      border: 0;
      outline: 0;
      color: $main-text-color;
      border-radius: 8px 8px 0 0;
      transform: translateY(8px);
      font-weight: bold;
      cursor: pointer;
      padding: 14px 12px;
      font-size: 15px;
      @include mediaLg {
        padding: 18px 12px;
        font-size: 18px;
      }

      &.selected {
        background-color: $main-app-color;
        color: #fff;
      }
    }
  }
}
