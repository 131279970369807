.general-products-page-data {
  .products-wrap {
    display: grid;
    gap: 18px;

    &.grid {
      grid-template-columns: repeat(auto-fill, minmax(292px, 1fr));
    }
    &.row {
      grid-template-columns: 1fr;
    }
  }
}
