@import '../../../scss/config';

.video-modal {
  .ant-modal-close {
    display: none;
    @include mediaLg {
      display: block;
    }
    color: #fff;
    .anticon {
      border: 1px solid #fff !important;
    }
  }
  .ant-modal-content {
    background-color: transparent;
    box-shadow: none;
  }
}
