@import '../../scss/config';

.main-app-bar-lg {
  position: relative;
  z-index: $app-header-z-index;
  background-color: $secondary-background-color;
  background-color: #f9f3fd;
  &.user-nav {
    @include mainAppBarHeight;
  }
  transform: translateY(0);
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  transition: all 0.25s ease-out;
  display: grid;
  grid-template-rows: auto 1fr;
  // &.not-home {
  //   background-color: #fff;
  // }

  .fixed-top-appbar {
    background-color: $main-app-color;
    padding: 2px 0;
    color: #fff;
    height: 42px;
    display: grid;
    align-items: center;
    a {
      color: #fff;
    }
    .section-content {
      display: grid;
      gap: 8px;
      align-items: center;
      grid-auto-flow: column;
      justify-content: space-between;
    }
    .email-phone-wrapper {
      display: flex;
      gap: 8px;
      align-items: center;
      a {
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }
    .other-content {
      display: grid;
      grid-auto-flow: column;
      gap: 18px;
      align-items: center;
      .language-wrap,
      .deliver-wrap {
        display: flex;
        align-items: center;
        gap: 5px;
      }
      .deliver-wrap {
        font-size: 14px;
      }
      .main-lang-btn {
        button {
          align-items: center;
        }
      }
    }
  }
  .nav-content-wrapper {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 18px;
    // align-items: center;
    &.not-home {
      // grid-template-columns: auto auto;
      .appbar-signin-link,
      .dashboard-link {
        background-color: $main-app-color;
        color: #fff;
      }

      .nav-lang-wrapper {
        & > ul {
          .main-nav-link,
          .main-nav-link-div {
            color: $main-text-color;
          }
        }
      }
    }
    .main-app-bar-logo {
      // padding: 2px 0;
      // padding-top: 14px;
      // padding-top: 4px;
      display: grid;
      align-items: center;
      img {
        height: 82px;
        // @include mediaXXlg {
        //   height: 102px;
        // }
        // @include mediaXXlg {
        //   height: 86px;
        // }
        transition: all 0.25s ease-out;
      }
    }

    .search-links-wrap {
      display: grid;
      grid-template-columns: 1fr auto;
      width: 100%;
      gap: 22px;
    }

    .appbar-signin-link {
      background-color: #fff;
      color: $main-app-color;
      padding: 12px 18px;
      border-radius: 12px;
      width: fit-content;
    }
    .dashboard-link {
      padding: 12px 18px;
      background-color: #fff;
      color: $main-app-color;
      border-radius: 12px;
      width: fit-content;
    }

    .main-search-lang-wrap {
      // padding-top: 8px;
      display: grid;
      grid-template-columns: 1fr auto;
      align-items: center;
      gap: 42px;
      position: relative;
      .signin-lang-wrap {
        justify-self: end;
        display: grid;
        grid-auto-flow: column;
        gap: 32px;
        align-items: center;
      }
    }

    .nav-lang-wrapper {
      order: -1;
      display: grid;
      grid-auto-flow: column;
      grid-gap: 8px;
      & > ul {
        display: grid;
        grid-auto-flow: column;
        gap: 2px;
        justify-content: space-evenly;
        li {
          display: grid;
          // align-items: center;
        }
      }
      .main-nav-link-div {
        align-items: center;
      }
      .main-nav-link,
      .main-nav-link-div {
        text-align: center;
        // padding-bottom: 14px;
        position: relative;
        color: $main-text-color;
        display: grid;
        cursor: pointer;
        & > span {
          margin-bottom: 4px;
          font-size: 13px;
        }
        & > div {
          display: grid;
          justify-items: center;
          align-items: center;
          grid-template-rows: 1fr auto;
        }
        .active-img-wrap {
          width: 44px;
          height: 2px;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translate(-50%);
        }
        &.active-link {
          color: $main-app-color;
          color: $secondary-app-color;
          .active-img-wrap {
            // background-image: url(../../assets/imgs/bgs/active-link-bg.png);
            background-color: $main-app-color;
            background-color: $secondary-app-color;
          }
        }
      }
    }
    // .bottom-navs {
    //   min-height: 52px;
    // }
  }

  &.light {
    .nav-content-wrapper {
      .nav-lang-wrapper {
        & > ul {
          li {
            a {
              color: #fff;
            }
          }
        }
      }
    }
  }

  &.exceeds0 {
    border-radius: 0 0 12px 12px;
    box-shadow: 0 12px 14px rgba(#000, 0.1);
    // background-color: #fff;
    z-index: calc(#{$app-header-z-index} + 1);
    &.scroll-up {
      // transform: translateY(calc(-52px - 42px));
      &.extra-bar {
        transform: translateY(-42px);
      }
    }

    .nav-content-wrapper {
      // grid-template-columns: auto auto;
      .appbar-signin-link {
        background-color: $main-app-color;
        color: #fff;
      }
      .dashboard-link {
        background-color: $main-app-color;
        color: #fff;
      }

      .main-app-bar-logo {
        // img {
        //   height: 52px;
        //   // height: 78px;
        // }
      }

      .nav-lang-wrapper {
        & > ul {
          li {
            a {
              color: $main-text-color;
            }
          }
        }
      }
    }
    &.scroll-down {
      transform: translateY(-100%);
    }
  }

  display: none;
  @include mediaLg {
    display: grid;
  }

  .profile-menu-btn {
    display: grid;
    gap: 8px;
    align-items: center;
    grid-auto-flow: column;
    padding: 0;
    min-height: 40px;
  }

  .notification-btn-wrap {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-top: 6px;
    svg {
      width: 24px;
      height: 24px;
      // path {
      //   fill: $main-app-color;
      // }
    }
    // .active-notifications {
    //   background-color: #ff8f8f;
    //   border: 2px solid #fff;
    //   width: 12px;
    //   height: 12px;
    //   border-radius: 50%;
    //   position: absolute;
    //   top: -6px;
    //   right: -1px;
    // }
  }

  &.user-nav {
    .nav-content-wrapper {
      .nav-lang-wrapper {
        order: 0;
      }
      .search-links-wrap {
        grid-template-columns: 1fr;
        gap: 0;
      }
    }
  }

  .merchent-notifications-profile-wrapper {
    display: flex;
    align-items: center;
  }
}

.notifications-dropdown-wrap {
  // background-color: $main-app-color !important;
  padding-inline-end: 4px !important;
  padding-bottom: 6px !important;
  padding-top: 6px !important;
  background-color: #fff;
  box-shadow:
    0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 9px 28px 8px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  .ant-dropdown-menu {
    min-width: 262px;
    max-width: 322px;
    overflow: auto;
    box-shadow: none !important;
    @include mediaLg {
      max-width: 732px;
    }
    max-height: 60vh;
    // overflow: hidden;
    // overflow-y: auto;
    @include custom-scrollbar;
    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-track {
      background-color: #eee;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #bababa;
    }
    border-radius: 8px !important;
    // padding: 12px 0;

    .ant-dropdown-menu-item {
      padding: 0;
      margin: 6px;
      border-radius: 4px;
      overflow: hidden;
    }
    .notifications-menu-link {
      border-bottom: 1px solid rgba(#000, 0.1);
      &:last-of-type {
        border-bottom: 0;
      }

      .ant-dropdown-menu-title-content {
        border: 1px solid rgba(#000, 0.05);
        display: grid;
        background-color: transparent !important;
      }
      .notification-content {
        grid-auto-flow: column;
        // text-align: center;
        padding: 10px 18px;
        transition: all 0.2s ease-out;
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 12px;
        align-items: center;
        margin-bottom: 3px;
        .notification-img {
          img {
            width: 52px;
          }
        }

        .notification-data {
          display: grid;
          gap: 6px;
        }
        .notification-title {
          color: $main-app-color;
          font-weight: bold;
          &.rtl {
            border-left: 1px solid rgba(#000, 0.1);
            padding-left: 8px;
          }
          &.ltr {
            border-right: 1px solid rgba(#000, 0.1);
            padding-right: 8px;
          }
        }

        .notification-title,
        .notification-desc {
          white-space: pre-wrap;
        }
        &:hover {
          background-color: #f1f1f1;
          box-shadow: 0 1px 2px rgba(#fff, 0.1);
        }

        &.not-read {
          background-color: lighten($main-app-color, 38%);
        }

        position: relative;
        // .not-read-indicator {
        //   position: absolute;
        //   top: 0;
        //   width: 3px;
        //   height: 100%;
        //   background-color: $main-app-color;
        //   &.rtl {
        //     left: 0;
        //   }
        //   &.ltr {
        //     right: 0;
        //   }
        // }
      }
    }
  }
}

.nav-links-ul {
  border-radius: 6px !important;
  .nav-link-li {
    padding: 8px 22px !important;
    text-align: center;
    &:hover {
      background-color: lighten($main-app-color, 30%);
      box-shadow: 0 2px 4px rgba(#000, 0.08);
    }
  }
}

.sigin-signup-dropdown {
  display: flex;
  gap: 4px;
  align-items: center;
  font-size: 12px;
  cursor: pointer;
}
.auth-dropdown-link {
  color: #6d6d6d;
}

.root-main-search-dropdown-list {
  padding: 4px !important;
  box-shadow:
    0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 9px 28px 8px rgba(0, 0, 0, 0.05);
  background-color: #fff;
  border-radius: 2px;

  .main-search-dropdown-list {
    max-height: 65vh;
    @include custom-scrollbar;
    overflow-y: auto;
    box-shadow: none;
  }
}
