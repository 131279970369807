@import '../../scss/config';

.cart-step-two {
  .addressess-summery-wrap {
    display: grid;
    gap: 32px;
    @include mediaLg {
      gap: 42px;
      grid-template-columns: 2fr 1fr;
    }
  }
}
