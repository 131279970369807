@import '../../scss/config';

.cart-payment-section {
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(217, 217, 217, 0.2);
  border-radius: 12px;
  align-self: start;
  margin-top: 22px;
  margin-bottom: 22px;
  padding: 14px;
  @include mediaLg {
    margin-top: 42px;
    margin-bottom: 42px;
    padding: 22px 24px;
  }
  h3 {
    color: $main-app-color;
    font-weight: bold;
    margin-bottom: 8px;
  }
  .btns-wrap {
    margin-top: 22px;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    // align-items: center;
    button {
      background-color: transparent;
      border: 0;
      outline: 0;
      border-radius: 6px;
      min-height: 72px;
      border: 2px solid #d9d9d9;
      padding: 6px 12px;
      min-width: 138px;
      display: flex;
      align-items: center;
      // flex-direction: column;
      gap: 4px;
      @include mediaLg {
        padding: 6px 28px;
      }
      background-color: #eee;
      transition: all 0.2s ease-out;
      cursor: pointer;
      p {
        display: flex;
        align-items: center;
      }
      &:hover,
      &.selected {
        background-color: $main-app-color;
        &.wallet-btn {
          color: #fff;
        }
      }

      &.tamara-btn,
      &.tabby-btn {
        padding: 2px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        border-width: 2px;
        &:hover,
        &.selected {
          border-color: $main-app-color;
        }
      }
      .tabby-btn {
      }

      &.online-btn {
        padding: 6px;
        @include mediaLg {
          padding: 6px;
        }

        .images-list {
          display: flex;
          gap: 0.2rem;
          align-items: center;
          img {
            width: 42px;
            height: auto;
            border-radius: 0.3rem;
          }
        }
      }
    }
  }
}
