@import '../../../scss/config';

.lefted-cart-single-page {
  .user-card-main-content {
    margin-top: 18px;
    display: flex;
    gap: 12px;
    flex-direction: column;
    @include mediaXlg {
      flex-direction: row;
    }
    .user-card {
      width: 292px;
      background-color: #ffffff;
      border: 1px solid #eaebed;
      box-shadow: 0px 4px 8px rgba(171, 171, 171, 0.05);
      border-radius: 8px;
      align-self: start;
      padding: 22px;
      margin: auto;

      @include mediaLg {
        padding: 32px 52px;
      }

      .user-img {
        background-color: transparent;
        margin-bottom: 12px;
        display: flex;
        justify-content: center;
        img,
        svg {
          width: 96px;
          height: 96px;
          object-fit: cover;
          object-position: center center;
          border-radius: 50%;
          border: 1px solid rgba($main-app-color, 0.1);
        }
      }
      .username {
        color: #290a44;
        font-size: 14px;
        text-transform: capitalize;
        text-align: center;
      }
      .phone-p {
        color: #6d6d6d;
        font-size: 14px;
        text-align: center;
      }
      .phones-btns {
        margin-top: 12px;
        display: flex;
        align-items: center;
        gap: 3px;
        justify-content: center;
        button {
          border: 0;
          outline: 0;
          background-color: transparent;
          img {
            width: 38px;
            height: 38px;
          }
        }
      }
    }

    .lefted-cart-products-table {
      .img-name-wrap {
        display: flex;
        align-items: center;
        gap: 8px;
        .pro-name {
          color: #6d6d6d;
        }
        .product-img {
          width: max-content;
          background-color: transparent;
          img,
          svg {
            width: 48px;
            height: 48px;
            object-fit: cover;
            object-position: center center;
            border-radius: 50%;
          }
        }
      }
    }

    .main-content {
      @include mediaXlg {
        width: calc(100% - 292px - 12px);
      }
      background-color: #fff;
      padding: 8px;
      border: 1px solid #eaebed;
      box-shadow: 0px 4px 8px rgba(171, 171, 171, 0.05);
      border-radius: 8px;
    }
  }
}
