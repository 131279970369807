@import '../../../scss/config';

.change-pass-page-body {
  @include mediaLg {
    padding: 32px;
  }
  border: 1px solid #eaebed;
  background-color: #fff;
  display: grid;
  @include mediaLg {
    grid-auto-flow: column;
  }
  gap: 12px;
  justify-content: space-between;
  max-width: 1200px;
  border-radius: 8px;
  .tips-section {
    padding: 0;
    @include mediaLg {
      padding: 22px;
    }
    h2 {
      margin-bottom: 12px;
    }
    ul {
      list-style: disc;
      padding: 0 42px;
      li {
        margin-bottom: 5px;
        color: #6d6d6d;
      }
    }
  }
}
