.create-coupon-form {
  .form-body {
    gap: 2px 22px;
    .name-phone-wrap,
    .email-nationality-wrap,
    .address-identity-wrap,
    .passwords-wrap {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 22px;
    }
  }
  .codes-radios-inputs-wrapper {
    .radios-wrap {
      .labels-wrap {
        margin-bottom: 18px;
        display: flex !important;
        grid-auto-flow: column;
        flex-wrap: wrap;
      }
    }

    .custom-date-picker-wrap {
      display: grid;
      .ant-picker {
        width: 100%;
        height: 48px;
      }
    }
    .coupon-date-label {
      input {
        max-height: 38px !important;
      }
    }

    .ant-picker.ant-picker-borderless {
      // background-color: #f1f2f3 !important;
      border: 1px solid #c4c4c4;
    }
  }
}
