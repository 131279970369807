@import '../../../scss/config';

.subscription-section {
  background-color: $main-app-color;
  padding: 22px 0 12px 0;
  @include mediaLg {
    padding: 38px 0 22px 0;
  }
  .custom-container {
    display: grid;
    gap: 32px;
    @include mediaLg {
      grid-template-columns: 1fr auto;
    }
  }

  .form-body {
    display: grid;
    gap: 18px;
    justify-content: start;
    @include mediaLg {
      grid-template-columns: 1fr auto;
      gap: 32px;
      justify-content: unset;
    }
  }
  .email-field-wrap {
    display: grid;
    gap: 18px;
    @include mediaLg {
      grid-template-columns: auto 1fr;
      gap: 38px;
    }

    input {
      height: 34px;
      border-radius: 4px;
      font-size: 18px;
    }

    .touch-p {
      color: #fff;
      text-transform: uppercase;
      font-size: 18px;
      @include mediaLg {
        margin-top: 12px;
      }
    }
  }

  .submit-btn {
    background-color: transparent;
    border: 1px solid #fff;
    border-radius: 3px;
    outline: 0;
    padding: 6px 42px;
    text-transform: uppercase;
    height: 49px;
    width: fit-content;
    @include mediaLg {
      width: auto;
    }
  }

  .social-links-wrapper {
    display: flex;
    gap: 18px;
    margin-top: 12px;
    align-content: flex-start;
    a {
      height: max-content;
    }
  }
}
