.blogs-section {
  .arch-all-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 32px;

    a {
      text-decoration: underline;
    }
  }
}
