.user-wallet-modal {
  max-width: 532px !important;
  .ant-modal-content .ant-modal-close .anticon {
    border: 0 !important;
    background-color: #c4c4c4 !important;
  }
  .ant-modal-body {
    padding: 24px 52px;
    margin-top: 32px;
    .wallet-img {
      display: block;
      margin: auto;
      margin-bottom: 12px;
    }
  }

  h2 {
    color: #290a44;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 32px;
  }
}
