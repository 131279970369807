.custom-google-map {
}

.main-map-marker-cls {
  position: absolute;
  top: 2px;
  padding: 10px 12px;
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid rgba(#000, 0.1);
  opacity: 0;
  visibility: hidden;
  transition: all 0.15s ease-out;
  font-size: 20px;
  &.selected {
    opacity: 1;
    visibility: visible;
  }
}
