@import '../../scss/config';

.home-offers-section {
  margin-bottom: 52px;
  .offers-wrap {
    display: grid;
    gap: 22px;
    @include mediaLg {
      grid-template-columns: 1fr 1fr;
    }

    .offer-link {
      position: relative;
      border-radius: 12px;
      overflow: hidden;
      display: grid;
      min-height: 382px;
      &:nth-of-type(1) {
        &::after {
          background-color: #fee1ee;
        }
        .main-title {
          color: #fb2e86;
        }
        .offer-value {
          color: #fb2e86;
        }
      }
      &:nth-of-type(2) {
        &::after {
          background-color: #f1e5fa;
        }
        .main-title {
          color: $main-app-color;
        }
        .offer-value {
          color: $main-app-color;
        }
      }
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      &.rtl {
        .offer-img {
          left: 0;
          right: auto;
        }

        .offer-tags {
          padding-right: 32px;
          padding-left: 0;
        }
        .sale-offer {
          transform: translateX(-20%);
        }
      }
    }

    .offer-data {
      display: grid;
      position: relative;
      z-index: 1;
      .offer-text {
        width: 55%;
        padding: 32px;
        .main-title {
          font-size: 30px;
          text-transform: capitalize;
        }
      }

      .offer-tags {
        margin-top: 18px;
        padding-left: 32px;
        list-style-type: disc;
        display: grid;
        gap: 8px;
        color: #aa8797;
        font-size: 18px;
      }
    }
    .sale-offer {
      position: absolute;
      bottom: 38px;
      left: 50%;
      transform: translateX(-80%);
      max-width: 98%;
      background-color: #fff;
      border-radius: 16px;
      display: flex;
      gap: 12px;
      align-items: center;
      color: #aa8797;
      padding: 12px 18px;
      font-size: 20px;
      .offer-value {
        font-size: 28px;
        font-weight: bold;
      }
    }
    .offer-img {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: 44%;
      img {
        width: 100%;
        height: 382px;
        object-fit: cover;
        object-position: center center;
      }
      svg {
        width: 100%;
        height: 382px;
        object-fit: cover;
      }
    }
  }
}
