@import '../../../scss/config';

.user-wallet-page {
  // background-color: #fff;
  .page-body {
    display: grid;
    place-items: center;
  }
  .wallet-balance {
    background: #f6eefc;
    box-shadow: 0px 4px 6px rgba(109, 109, 109, 0.11);
    border-radius: 16px;
    min-width: 40%;
    min-height: 300px;
    padding: 18px;
    display: grid;
    place-content: center;
    text-align: center;
    .wallet-title {
      color: #6d6d6d;
      font-size: 28px;
      margin-bottom: 18px;
    }
    .wallet-value {
      color: #290a44;
      font-size: 34px;
      font-weight: bold;
    }

    .charge-btn {
      margin-top: 32px;
      display: flex;
      justify-content: center;
      gap: 6px;
      align-items: center;
      padding: 12px 24px;
      @include mediaLg {
        padding: 12px 32px;
      }
      border-radius: 4px;
      background-color: #9d4edd;
      border: 0;
      outline: 0;
      color: #fff;
      cursor: pointer;
    }
  }
}
