.store-view-terms-modal {
  .ant-modal-close {
    display: none;
  }
  .h3-btn {
    position: relative;
    button {
      background-color: transparent;
      border: 0;
      outline: 0;
      cursor: pointer;
      position: absolute;
      top: 0px;
      right: 0;
      left: auto;
    }
  }

  &.rtl {
    .h3-btn {
      button {
        left: 0;
        right: auto;
        img {
          height: 22px;
        }
      }
    }
  }
}
