@import '../../scss/config';

.main-app-bar-md {
  // background-color: $main-app-color;
  background-color: #f9f3fd;
  position: relative;
  z-index: $app-header-z-index;

  &.not-home {
    background-color: #fff;
    .nav-content-wrapper {
      .nav-lang-wrapper {
        button {
          color: #000;
        }
      }
    }
  }
  .nav-content-wrapper {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    grid-gap: 32px;
    align-items: center;

    .main-app-bar-logo {
      padding: 2px 0;
      img {
        height: 48px;
      }
    }

    .nav-lang-wrapper {
      display: grid;
      grid-auto-flow: column;
      grid-gap: 14px;
      align-items: center;

      button {
        background-color: transparent;
        border: 0;
        color: #fff;
        font-size: 18px;
        padding: 2px 8px;
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }
  }

  &.light {
    .nav-content-wrapper {
      .nav-lang-wrapper {
        & > ul {
          li {
            a {
              color: #fff;
            }
          }
        }
      }
    }
  }

  @include mainAppBarHeight;
  transform: translateY(0);
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  transition: all 0.25s ease-out;
  display: grid;
  align-items: center;

  &.exceeds0 {
    border-radius: 0 0 12px 12px;
    box-shadow: 0 12px 14px rgba(#000, 0.1);
    background-color: #fff;
    z-index: calc(#{$app-header-z-index} + 1);
    // &.scroll-up {
    // }
    .nav-content-wrapper {
      .nav-lang-wrapper {
        button {
          color: #000;
        }
      }
      .main-app-bar-logo {
        img {
          height: 48px;
        }
      }
    }
    &.scroll-down {
      transform: translateY(-100%);
    }
  }

  @include mediaLg {
    display: none;
  }
}

.header-md-drawer {
  .ant-drawer-content-wrapper {
    width: 332px !important;
  }
  .ant-drawer-body {
    padding: 0;
    overflow-x: hidden;
  }

  .main-nav-link {
    padding: 8px 24px;
    display: block;
    margin-bottom: 4px;
  }
  .MuiBackdrop-root {
    background-color: rgba(#000, 0.7);
  }

  ul {
    .nav-link {
      display: grid;
      padding: 12px 18px;
      font-size: 18px;
      border-radius: 8px;
      position: relative;
      font-weight: bold;
      &:hover {
        color: $main-app-color;
      }
      &.active-link {
        background-color: $main-app-color;
        color: #fff;
        &:hover {
          color: #fff;
        }
      }
    }
  }

  .appbar-signin-link {
    margin: 8px 24px;
    display: grid;
    padding: 12px 18px;
    font-size: 18px;
    border-radius: 8px;
    position: relative;
    font-weight: bold;
    &:hover {
      color: $main-app-color;
    }
    &.active-link {
      background-color: $main-app-color;
      color: #fff;
      &:hover {
        color: #fff;
      }
    }
  }
  .username-img {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    align-items: center;
    .user-img {
      img,
      svg {
        height: 38px;
        width: 38px;
        border-radius: 50%;
        object-fit: cover;
        object-position: center center;
      }
      .username {
        color: $main-app-color;
      }
    }
  }
  .signout-messages-wrap {
    margin: 8px 24px;

    button {
      cursor: pointer;
      background-color: #eee;
      border: 0;
      outline: 0;
      padding: 8px 22px;
      border-radius: 4px;
    }
    .messages-wrap {
      margin-top: 12px;
      background-color: #eee;
      width: fit-content;
      border-radius: 4px;
      padding: 6px 22px;

      .notification-btn-wrap {
        cursor: pointer;
        display: flex;
        gap: 4px;
        align-items: center;
      }
    }
  }
}
