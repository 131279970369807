@import '../../../scss/config';

.single-model-page {
  // background-color: #fff;
  min-height: 100vh;
  padding-bottom: 62px;

  .image-gallery-content .image-gallery-slide .image-gallery-image {
    border-radius: 4px;
    height: 40vh;
    @include mediaLg {
      height: 50vh;
    }
    width: 100%;
    object-fit: cover;
    object-position: top center;
  }

  .video-gallery {
    border-radius: 4px;
    width: 100%;
    object-fit: cover;
    object-position: top center;
    height: 40vh;

    @include mediaLg {
      height: 50vh;
    }
  }
  .video-thumbnail {
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top center;
    }
    &::after {
      background-image: url('../../../assets/imgs/icons/play-video.png');
      background-size: 54% 54%;
      background-repeat: no-repeat;
      background-position: center center;
      background-color: #fb2e8666;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-blend-mode: multiply;
    }
  }
  .image-gallery-thumbnail {
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top center;
    }
    &::after {
      background-repeat: no-repeat;
      background-position: center center;
      background-color: #fb2e8666;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-blend-mode: multiply;
    }
  }
  .discount-badge {
    z-index: 1;
    background-color: #ff9ec8;
    padding: 4px 9px;
    border-radius: 5px;
    position: absolute;
    left: 4px;
    top: 4px;
    font-size: 14px;
    color: #c90e5e;
  }
  .discount-countdown-badge {
    z-index: 1;
    background-color: rgba(#000, 0.4);
    padding: 5px 12px;
    border-radius: 5px;
    position: absolute;
    left: 4px;
    top: 42px;
    font-size: 14px;
    letter-spacing: 0.5px;
    color: #fff;
  }

  .gallery-wrap {
    &:hover {
      .views-count-badge {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  .discount-countdown {
    color: $secondary-text-color;
    span {
      letter-spacing: 1px;
    }
  }

  .ingredients-guarantee-wrapper {
    font-size: 14px;
    color: $secondary-text-color;
  }

  .product-details-section {
    padding: 22px 0;
    @include mediaLg {
      padding: 32px 0;
    }

    .currency-span {
      font-size: 65%;
      margin-inline-start: 3px;
    }
  }
  .gallery-details-wrap {
    @include mediaLg {
      display: flex;
      flex-wrap: wrap;
      gap: 22px;
    }
    @include mediaXlg {
      gap: 42px;
    }

    .gallery-wrap {
      position: relative;
      flex: 1 1;
      .image-gallery-thumbnails-container {
        margin-top: 12px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        button {
          border: 2px solid transparent;
          width: 72px;
          height: 72px;
          border-radius: 8px;
          overflow: hidden;
          &:hover,
          &.active {
            border-color: $main-app-color;
          }
          img {
            // max-height: 62px;
            width: 72px;
            height: 72px !important;
            object-fit: cover;
            object-position: center center;
          }
        }

        .image-gallery-thumbnail-inner {
          height: fit-content;
        }
      }
      // background-color: #fff;
      align-self: flex-start;
    }
    .details-parent-wrap {
      flex: 1 1;
      // flex: 0.8 1;

      .cat-catFilter {
        color: $main-app-color;
        font-size: 14px;
      }

      .action-btns-wrap {
        display: flex;
        gap: 8px;
        align-items: center;
        .action-btn-itself {
          background: #ffffff;
          box-shadow: 0px 0px 8px rgba(229, 229, 229, 0.6);
          border-radius: 3.2px;
          width: 64px;
          height: 64px;
          display: grid;
          place-items: center;
          border: 0;
          outline: 0;

          cursor: pointer;
          svg {
            width: 22px;
          }
        }
      }

      .p-title {
        color: #290a44;
        font-size: 22px;
        display: flex;
        font-weight: bold;
        text-transform: capitalize;
        gap: 0.3rem;
        margin-bottom: 1rem;
        @include mediaXlg {
          font-size: 30px;
          margin-bottom: 1.2rem;
        }

        .stock-label {
          font-size: 0.8rem;
          align-self: end;
          line-height: 2;
          .in-stock {
            color: #25d366;
          }
          .out-of-stock {
            color: red;
          }
        }
      }
    }

    .order-model-form {
      .price-options-checkboxes {
        .ant-checkbox-group {
          flex-direction: column;
          gap: 1rem;
        }
      }

      .model-payment-section {
        margin-top: 1.5rem;
        h3 {
          color: $main-app-color;
          font-weight: bold;
          margin-bottom: 8px;
        }
        .payment-btns-wrap {
          margin-top: 22px;
          display: flex;
          flex-wrap: wrap;
          gap: 12px;
          // align-items: center;
          button {
            background-color: transparent;
            border: 0;
            outline: 0;
            border-radius: 6px;
            min-height: 62px;
            border: 2px solid #d9d9d9;
            padding: 6px 15px;
            min-width: 138px;
            display: flex;
            align-items: center;
            // flex-direction: column;
            gap: 4px;
            @include mediaLg {
              padding: 6px 28px;
            }
            background-color: #eee;
            transition: all 0.2s ease-out;
            cursor: pointer;
            p {
              display: flex;
              align-items: center;
            }
            &:hover,
            &.selected {
              background-color: $main-app-color;
              &.wallet-btn {
                color: #fff;
              }
            }

            &.tamara-btn,
            &.tabby-btn {
              padding: 2px;
              background-repeat: no-repeat;
              background-size: cover;
              background-position: center center;
              border-width: 2px;
              &:hover,
              &.selected {
                border-color: $main-app-color;
              }
            }
            // .tabby-btn {
            // }

            &.online-btn {
              padding: 6px 10px;
              @include mediaLg {
                padding: 6px 10px;
              }

              .images-list {
                display: flex;
                gap: 0.2rem;
                align-items: center;
                li {
                  display: flex;
                }
                img {
                  width: 42px;
                  height: auto;
                  border-radius: 0.3rem;
                }
              }
            }
          }
        }
      }

      .submit-btn {
        background-color: lighten($main-app-color, 10%);
        padding: 8px 1.2rem;
        border: 1px solid #eaebed;
        border-radius: 8px;
        justify-content: center;
        cursor: pointer;
        transition: all 0.15s ease-out;
        p {
          display: flex;
          gap: 4px;
          align-items: center;
        }
        &:hover {
          background-color: $main-app-color;
        }
      }
    }
  }
  .model-desc {
    margin-bottom: 2rem;

    .desc-content {
      line-height: 1.8;
      color: #6d6d6d;
    }
  }

  //
  .img-badge-wrap {
    position: relative;
  }
  .product-details-fallback-img {
    svg {
      max-height: 500px !important;
    }
  }

  .saloonStoreLink {
    background-color: #f3eafb;
    color: #9d4edd;
    padding: 14px 28px;
    height: 60px;
    border-radius: 8px;
    margin-top: 18px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    transition: all 0.2s ease-out;
    &:hover {
      background-color: #e4d0f7;
    }
  }
}
