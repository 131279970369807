@import '../../scss/config';
.user-cart-page {
  padding: 32px 0;
  @include mediaLg {
    padding: 52px 0;
  }

  .breadcrumb-title {
    margin-bottom: 18px;
    @include mediaLg {
      margin-bottom: 32px;
    }
  }

  .items-summery-wrap {
    display: grid;
    gap: 22px;
    @include mediaLg {
      grid-template-columns: 1fr 30%;
    }
  }
  .cart-cards-wrap {
    display: grid;
    align-content: start;
    gap: 22px;

    .cart-card {
      background-color: #fff;
      border-radius: 12px;
      display: grid;
      overflow: hidden;
      gap: 18px;
      border: 1px solid #eee;
      @include mediaLg {
        grid-template-columns: auto 1fr auto;
      }

      .offer-with-discount-wrapper {
        display: flex;
        gap: 6px;
        align-items: center;
      }

      .p-logo,
      .custom-img-wrap {
        display: grid;
        align-items: center;
        img,
        svg {
          // width: 167px;
          width: 167px !important;
          height: 167px !important;
          // height: 100%;
          object-fit: cover;
          object-position: top center;
          border-radius: 8px;
        }
      }

      .main-content {
        padding: 12px;
        display: grid;
        align-content: space-around;
        .name-store-wrap {
          display: flex;
          align-items: center;
          gap: 8px;
          .p-name {
            color: #290a44;
            font-size: 20px;
            text-transform: capitalize;
          }
          .store-name {
            color: $secondary-app-color;
            font-size: 15px;
          }
        }
        .date-time-wrap {
          color: #6d6d6d;
          display: flex;
          gap: 6px;
          align-items: center;
        }
        .option-p {
          color: #6d6d6d;
        }
        .refund-title {
          display: flex;
          align-items: center;
          gap: 3px;
          color: $secondary-app-color;
          font-size: 14px;
          svg {
            margin-bottom: 2px;
          }
        }

        .p-price {
          span {
            color: #c4c4c4;
          }
        }
      }

      .card-actions-price-wrap {
        padding: 12px;
        display: grid;
        justify-content: space-between;
        grid-auto-flow: column;
        align-items: center;
        @include mediaLg {
          grid-auto-flow: row;
          align-content: space-between;
          justify-content: end;
        }
        .remove-btn {
          border: 0;
          outline: 0;
          padding: 0;
          background-color: transparent;
          display: flex;
          justify-content: flex-end;
          cursor: pointer;
          svg {
            width: 28px;
            height: 28px;
          }
        }

        .price-btns-wrap {
          display: flex;
          flex-wrap: wrap;
          gap: 6px;
          @include mediaLg {
            flex-direction: column;
          }
        }

        .p-total-price {
          color: $main-app-color;
          font-size: 22px;
          text-align: center;
        }
        .counter-btns-wrap {
          display: flex;
          align-items: center;
          gap: 10px;
          .count-btn {
            border: 0;
            outline: 0;
            background-color: #f3eafb;
            border-radius: 6px;
            width: 44px;
            height: 44px;
            font-size: 24px;
            cursor: pointer;
            color: $main-app-color;
          }
          .count-value {
            font-size: 20px;
            min-width: 22px;
            text-align: center;
            color: $main-app-color;
          }
        }
      }
    }
  }

  .summery-wrap {
    .summery-card {
      padding: 18px;
      border-radius: 12px;
      background: #fff;
      border: 1px solid rgba(#000, 0.05);
      // box-shadow: 0px 4px 12px rgba(217, 217, 217, 0.2);

      .title {
        color: #290a44;
        font-size: 20px;
        margin-bottom: 12px;
      }
      .sub-total,
      .fees,
      .discount {
        border-bottom: 1px solid #c4c4c4;
        color: #6d6d6d;
        padding-bottom: 6px;
        margin-bottom: 6px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .order-total {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #290a44;
        margin-top: 18px;
        font-weight: bold;
        span {
          &:last-of-type {
            font-size: 20px;
            @include mediaLg {
              font-size: 24px;
            }
          }
        }

        .currency-symbol {
          font-size: 60% !important;
          margin-inline-start: 3px;
        }
      }
    }
  }

  .discount-code-form {
    // margin-top: 12px;
    background-color: #fff;
    padding: 28px 24px;
    border-radius: 12px;
    border: 1px solid rgba(#000, 0.05);
  }

  .btns-wrapper {
    display: flex;
    gap: 12px;
    align-items: center;
    .checkout-btn {
      background-color: $main-app-color;
      padding: 12px 52px;
      font-size: 22px;
      border-radius: 4px;
      color: #fff;
      border: 0;
      outline: 0;
      height: fit-content;
      cursor: pointer;
    }

    .back-btn {
      background-color: transparent;
      padding: 12px 52px;
      border-radius: 4px;
      color: #c4c4c4;
      border: 0;
      outline: 0;
      height: fit-content;
      cursor: pointer;
    }
  }

  .cart-steps-indicator {
    max-width: 1024px;
    margin: auto;
  }
  .cart-stepper-form-wrap {
    margin: 28px 0;
    @include mediaLg {
      margin: 62px 0;
    }
  }

  .btns-wrapper {
    gap: 5%;
    justify-content: space-between;
    .checkout-btn {
      padding: 12px;
      width: 45%;
      font-size: 16px;
    }

    .back-btn {
      width: 45%;
      padding: 12px;
      font-size: 14px;
      @include mediaLg {
        font-size: 15px;
      }
    }
  }
}
