.custom-view-as-wrapper {
  display: flex;
  align-items: center;
  gap: 4px;
  .view-as-btn {
    border: 0;
    outline: 0;
    background-color: transparent;
    display: grid;
    place-items: center;
    padding: 4px;
    cursor: pointer;
    svg {
      height: 32px;
    }
  }
}
