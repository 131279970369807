@import '../../../scss/config';

.merchent-employees-page {
  .title-create-employee-btn-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 12px;
    margin-bottom: 22px;

    .new-employee-btn {
      transition: all 0.2s ease-out;
      border: 1px solid $main-app-color;
      color: $main-app-color;
      background-color: lighten($main-app-color, 38%);
      border-radius: 8px;
      display: flex;
      align-items: center;
      gap: 6px;
      cursor: pointer;
      padding: 9px 18px;
      @include mediaLg {
        font-size: 18px;
        padding: 9px 30px;
      }
      svg {
        width: 22px;
        height: 22px;
        path {
          fill: $main-app-color;
        }
      }
      &:hover {
        background-color: lighten($main-app-color, 38%);
      }
    }
  }

  .stores-wrap {
    display: grid;
    gap: 18px;
  }

  .roles-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 3px;
    .role-tag {
      padding: 4px 12px;
      border-radius: 99px;
      background-color: #fb2e860d;
      color: #fb2e86;
      font-size: 13px;
    }
  }

  .emp-name-photo-wrap {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
    .custom-image {
      width: max-content;
      // background-color: transparent;
      border-radius: 50%;
      width: 50px !important;
      height: 50px !important;
    }
    svg {
      width: 28px !important;
    }
    img {
      width: 48px !important;
      height: 48px !important;
      border-radius: 50%;
      object-fit: cover;
      object-position: center center;
    }
    .name-phone {
      text-align: start;
      color: #6d6d6d;
      font-size: 13px;
    }
  }

  .action-btns-wrap {
    justify-content: center;
  }
}
