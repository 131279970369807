@import '../../scss/config';

.users-wrapper {
  align-self: start;
  display: grid;
  .users {
    display: grid;
    justify-content: start;
    grid-gap: 32px;
    .user {
      display: grid;
      grid-auto-flow: column;
      justify-content: start;
      align-items: center;
      grid-gap: 8px;
      padding: 10px 12px;
      border-radius: 8px;
      cursor: pointer;
      border: 1px solid rgba(#000, 0.1);
      transition: all 0.2s ease-out;
      .user-name {
        .names {
          display: flex;
          gap: 8px;
        }
        .last-msg {
          max-width: 140px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
      &:hover {
        background-color: #fff;
      }
      &.active-chat {
        background-color: #f6e793;
        border: 1px solid rgba(#000, 0.1);
      }
    }
  }

  .groups-Skeleton {
    display: grid;
    grid-gap: 8px;
    .skeleton-row {
      display: grid;
      grid-template-columns: auto 1fr;
      align-items: center;
      grid-gap: 12px;
    }
  }
}
