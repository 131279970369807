.store-reactivate-modal {
  .ant-modal-body {
    padding: 62px 24px;
    text-align: center;
    .modal-content {
      color: #25d366;
      display: grid;
      place-items: center;
      img {
        margin-bottom: 12px;
      }
      p {
        font-size: 22px;
      }
    }
    .modal-btns {
      margin-top: 42px;
      display: flex;
      gap: 22px;
      button {
        border: 0;
        outline: 0;
        padding: 10px 22px;
        border-radius: 4px;
        height: auto !important;
        font-size: 18px;
      }
      .submit-btn {
        // background-color: rgba(37, 211, 102, 0.1);
        background-color: #25d366;
        color: #fff;
        // color: #25d366;
      }

      .cancel-btn {
        background-color: #eaebed;
      }
    }
  }
}
