@import '../../scss/config';

.signup-terms-modal {
  .ant-modal-body {
    line-height: 2;
    padding: 14px !important;
    @include mediaLg {
      padding: 24px;
    }
  }
}
