@import '../../../scss/config';

.merchent-stores-page {
  .title-create-store-btn-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .new-store-btn {
      transition: all 0.2s ease-out;
      border: 1px solid $main-app-color;
      color: $main-app-color;
      background-color: lighten($main-app-color, 38%);
      padding: 9px 30px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      gap: 6px;
      font-size: 18px;
      svg {
        width: 22px;
        height: 22px;
        path {
          fill: $main-app-color;
        }
      }
      &:hover {
        background-color: lighten($main-app-color, 38%);
      }
    }
  }

  .stores-wrap {
    display: grid;
    gap: 18px;
  }
}
