@import '../../../scss/config';

.models-drawer-filter-wrapper {
  .filter-btn {
    background-color: $main-app-color;
    color: #fff;
    height: auto;
    border: 0;
    outline: 0;
    padding: 8px 22px;
    border-radius: 4px;
  }
  @include mediaLg {
    display: none;
  }
}

.models-drawer {
  .ant-drawer-content-wrapper {
    width: 80%;
    max-width: 332px;
  }
  .models-filter {
    display: block;
    @include mediaLg {
      display: none;
    }
  }
}

.models-filter-lg {
  justify-content: flex-start;
  display: none;
  padding: 6px 8px;
  background-color: white;
  border-radius: 0.8rem;
  border: 1px solid #e0e0e0;
  @include mediaLg {
    display: block;
    min-height: 442px;
    width: 252px;
  }

  .filter-wrapper-label {
    padding: 0.3rem 0.3rem 0.6rem 0.3rem;
    font-size: 1rem;
    border-bottom: 1px solid rgba(#000, 0.15);
  }

  .form-body {
    margin-inline-start: 0.4rem;
  }

  .section-title {
    margin-bottom: 0.3rem;
    font-size: 1rem;
    color: $main-app-color;
    margin-top: 0.8rem;
  }

  .shared-filter-list-wrapper,
  .section-checkboxs-wrap {
    margin-inline-start: 0.4rem;
  }

  .section-checkboxs-wrap {
    display: grid;
    .ant-checkbox-group {
      display: grid;
      gap: 8px;
    }
  }

  .filter-content {
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: $secondary-app-color !important;
      border-color: $secondary-app-color !important;
    }
    .ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .ant-checkbox:hover .ant-checkbox-inner,
    .ant-checkbox-input:focus + .ant-checkbox-inner {
      border-color: $secondary-app-color !important;
    }
    .ant-checkbox-checked::after {
      border: 1px solid $secondary-app-color !important;
    }
    .ant-checkbox-wrapper-checked {
      & > span {
        color: $secondary-app-color !important;
      }
    }
  }
}
