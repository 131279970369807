@import '../../scss/config';

.select-address-wrap {
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(217, 217, 217, 0.2);
  border-radius: 12px;
  align-self: start;
  padding: 14px;
  @include mediaLg {
    padding: 22px 24px;
  }

  .header-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 12px;
    justify-content: space-between;

    h3 {
      color: $main-app-color;
      font-weight: bold;
      margin-bottom: 8px;
    }
    .add-address {
      display: flex;
      gap: 5px;
      align-items: center;
      background-color: #f6eefc;
      margin-top: -12px;
      padding: 6px 12px;
      font-size: 14px;
      border-radius: 6px;
      svg {
        height: 22px;
        width: 22px;
      }
    }
  }
  .radios-wrap {
    padding: 12px;
    padding-bottom: 0;
  }
  .labels-wrap {
    color: #6d6d6d;
    display: grid;
    grid-auto-flow: row !important;
    gap: 4px !important;
    input {
      display: none;
    }
    .radio-input-label {
      cursor: pointer;
      border-radius: 5px;
      border-left: 5px solid transparent;
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 10px 6px;
      @include mediaLg {
        padding: 12px 6px;
      }

      svg {
        width: 20px;
        height: 20px;
      }
      &.rtl {
        border-left: 0;
        border-right: 5px solid transparent;
      }
      &.selected {
        background-color: rgba(157, 78, 221, 0.05);
        border-color: $main-app-color;
      }
    }
  }
}
