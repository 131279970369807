@import '../../../scss/config';

.all-statistics-page {
  .boxes-wrap {
    display: grid;
    gap: 1rem;
    max-width: 1000px;
    justify-content: center;
    margin: auto;
    margin-top: 2rem;
    margin-bottom: 2rem;
    grid-template-columns: 1fr 1fr;
    @include mediaLg {
      margin-top: 4rem;
      grid-template-columns: repeat(6, 1fr);
    }

    .s-box {
      background-color: #fff;
      border: 1px solid;
      border-radius: 8px;
      display: grid;
      gap: 1.5rem;
      place-items: center;
      padding: 18px 14px;
      @include mediaLg {
        padding: 32px 22px;
      }
      @include mediaLg {
        grid-column-end: span 2;
        &:first-of-type {
          grid-column-start: 2;
        }
      }
      .value-p {
        font-size: 20px;
        font-weight: bold;
      }
      &.danger {
        color: #fb2e86;
        border-color: #fb2e86;
      }
      &.green {
        border-color: #25d366;
        color: #25d366;
      }
      &.primary {
        border-color: #9d4edd;
        color: #9d4edd;
      }
    }
  }
}
