@import '../../../scss/config';

.tutorial-page {
  .tut-page-logo {
    display: block;
    margin: auto;
    margin-bottom: 32px;
    width: max-content;
  }

  .page-content {
    max-width: 1100px;
    margin: auto;
    background-color: #fff;
    padding: 32px 32px;
    padding-bottom: 52px;
    border-radius: 12px;
    box-shadow: 0px 0px 25px 10px #f8f8fb;

    .arch-title {
      font-weight: bold;
      color: $main-app-color;
    }

    p {
      line-height: 2;
      max-width: 800px;
      margin: auto;
      text-align: center;
      font-size: 20px;
      color: $main-text-color;
    }

    .video-placeholder-wrap {
      position: relative;
      display: grid;
      place-items: center;
      max-width: 1110px;
      margin: auto;
      margin-top: 22px;
      padding-top: 38px;
      border-radius: 8px;
      overflow: hidden;
      cursor: pointer;
      outline: 0;
      border: 0;
      padding: 0;

      &::after {
        content: '';
        width: 100%;
        height: 100%;
        background-color: rgba($main-app-color, 0.2);
        top: 0;
        left: 0;
        position: absolute;
      }
      img {
        max-width: 100%;
      }
      .icon-wrap {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        background-color: #fff;
        border-radius: 50%;
        padding: 22px;
        width: 78px;
        height: 78px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border: 0;
        outline: 0;
        svg {
          width: 42px;
          height: 42px;
        }
        .icon-img {
          width: 72px;
          height: 72px;
        }
      }
    }
  }
}
