@import '../../scss/config';

.app-layout {
  // display: flex;
  // background-color: #fff;
  min-height: 100vh;
  .layout-content {
    // background-color: #f9f8f8;
    min-height: 80vh;
    min-height: 82vh;
    & > [class$='page'] {
      min-height: 80vh;
      min-height: 82vh;
    }
  }

  .whatsapp-app-link {
    position: fixed;
    bottom: 2rem;
    inset-inline-end: 2rem;
    background-color: #0dc143;
    color: #fff;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    font-size: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
