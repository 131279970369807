@import '../../../scss/config';

.rate-order-modal {
  .ant-modal-body {
    padding: 42px !important;
    display: grid;
    justify-items: center;
  }

  .rate-title {
    color: #290a44 !important;
    font-weight: bold;
    font-size: 38px;
    margin-bottom: 32px;
    text-align: center;
  }
  .rate-text {
    font-size: 20px;
    color: #6d6d6d;
    margin-bottom: 38px;
    text-align: center;
    max-width: 400px;
    line-height: 1.8;
  }
  .rate-comp {
    svg {
      width: 30px;
      height: 30px;
    }
  }

  .submit-rate-btn {
    margin-top: 32px;
    background-color: $main-app-color;
    color: #fff;
    border-radius: 5px;
    padding: 10px 30px;
    border: 0;
    outline: 0;
    cursor: pointer;
    @include mediaLg {
      padding: 12px 38px;
    }
  }
}
