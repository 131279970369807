@import '../../../scss/config';

.user-coupons-page {
  // background-color: #fff;
  .page-body {
    margin-top: 16px;
    display: grid;

    .page-content-wrapper {
      .list-wrapper {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(192px, 1fr));
        gap: 1rem;
      }
    }
  }
}
