@import '../../scss/config';

.top-saloons-page {
  // background-color: #fff;
  .arch-heading {
    margin-top: 1rem;
  }
  .custom-container {
    max-width: 1242px;
    margin: auto;
  }

  .saloons-wrap {
    background-color: #f7f8fa;
    border: 1px solid rgba(#000, 0.1);
    padding: 42px;
    border-radius: 12px;
    margin: auto;
    margin-bottom: 1rem;
    margin-top: 0;
    gap: 1.5rem 1rem;
    justify-content: center;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    @include mediaMd {
      grid-template-columns: repeat(3, 1fr);
    }
    @include mediaLg {
      grid-template-columns: repeat(6, 1fr);
    }
  }

  .list-pagination-wrap {
    margin-bottom: 2rem;
  }
  .saloons-pagination {
    padding: 0 42px;
  }
}
