@import '../../../scss/config';

.merchent-other-page {
  background-color: #f7f8fa;

  .title-create-store-btn-wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 12px;
    @include mediaLg {
      gap: 32px;
    }

    .other-orders-link {
      font-size: 18px;
      display: flex;
      align-items: center;
      gap: 6px;
      background-color: lighten($main-app-color, 30%);
      color: $main-app-color;
      padding: 8px 18px;
      border-radius: 6px;
    }
  }

  .other-form-files-wrapper {
    margin-top: 22px;
    display: grid;
    gap: 32px;
    margin-bottom: 22px;
    .files-wrapper {
      background-color: #fff;
      border-radius: 12px;
      height: fit-content;
      min-height: 222px;
      padding: 18px;
      text-align: center;
      display: grid;
      align-content: center;

      .files-label {
        font-weight: bold;
      }
      .links-wrapper {
        margin: auto;
        width: fit-content;
        margin-top: 28px;
        display: flex;
        flex-wrap: wrap;
        gap: 14px;
        .download-link {
          width: max-content;
          background-color: #eee;
          padding: 10px 22px;
          border-radius: 8px;
          display: flex;
          gap: 8px;
          align-items: center;
        }
      }
    }
  }
  .create-other-service-form {
    // max-width: 832px;
    padding: 18px 22px;
    border-radius: 12px;
    border: 1px solid rgba(#000, 0.1);
    .radios-group-title {
      font-weight: bold;
    }

    .need-label {
      display: flex !important;
      flex-wrap: wrap;
      gap: 1rem !important;
      .radio-input-label {
        svg {
          width: 48px !important;
          height: 48px !important;
        }
      }

      .radio-input-label {
        display: grid;
        grid-auto-flow: row !important;
        justify-items: center;
        gap: 4px;
        // padding: 14px 8px;
        border: 1px solid #eaebed;
        box-shadow: 0px 4px 8px rgba(171, 171, 171, 0.05);
        border-radius: 6px;
        background: #ffffff;
        justify-content: center !important;
        padding: 22px 1.2rem;
        cursor: pointer;
        min-width: 150px;
        min-height: 100px;
        width: 100%;
        flex: 1;
        @include mediaLg {
          // min-width: 222px;
          max-width: 285px;
          min-height: 100px;
        }
        place-items: center;

        &.selected {
          background-color: lighten($main-app-color, 25%);
          box-shadow: 0px 4px 8px rgba(171, 171, 171, 0.1);

          .label-span {
            // color: $main-app-color !important;
            color: #fff !important;
          }
        }

        .label-span {
          font-weight: bold;
          margin-bottom: 14px;
          margin-top: 12px;
        }
      }
    }

    .radio-input-label {
      display: grid;
      grid-auto-flow: row !important;
      justify-items: center;
      gap: 4px;
      // padding: 14px 8px;
      border: 1px solid #eaebed;
      box-shadow: 0px 4px 8px rgba(171, 171, 171, 0.05);
      border-radius: 6px;
      background: #ffffff;
      justify-content: center !important;
      padding: 22px 22px;
      cursor: pointer;
      min-width: 150px;
      min-height: 100px;
      place-items: center;

      &.selected {
        background-color: lighten($main-app-color, 25%);
        box-shadow: 0px 4px 8px rgba(171, 171, 171, 0.1);

        .label-span {
          // color: $main-app-color !important;
          color: #fff !important;
        }
      }

      .label-span {
        font-weight: bold;
        margin-bottom: 14px;
        margin-top: 12px;
      }

      .payment-img {
        border-radius: 8px;
        // background-color: #fff;
        // padding: 14px 18px;
        text-align: center;
        p {
          line-height: 1.6;
        }
        img {
          margin-bottom: 12px;
          max-width: 102px;
          height: auto;
        }
      }
      &.selected {
        background-color: lighten($main-app-color, 8%);
        .payment-img {
          background-color: lighten($main-app-color, 8%);
          color: #fff;
        }
      }
    }

    .payment-radios {
      margin-top: 22px;
      background-color: #fff;
      padding: 18px;
      border-radius: 12px;
      @include mediaLg {
        padding: 32px 22px;
      }

      .radios-group-title {
        margin-bottom: 18px !important;
      }

      .labels-wrap {
        align-items: stretch !important;
        grid-auto-flow: row !important;

        @include mediaLg {
          grid-template-columns: repeat(3, 1fr) !important;
          grid-auto-flow: column !important;
        }
      }
    }

    .select-label-wrap {
      margin-top: 32px;
      .label-p {
        font-weight: bold;
      }
    }

    .submit-btn {
      margin-top: 42px;
      padding: 8px 52px;
      height: 56px;
    }
  }
}
