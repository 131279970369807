@import '../../scss/config';

.saloon-card {
  .saloon-img {
    width: 141px;
    height: 141px;
    border-radius: 50%;
    position: relative;
    background-image: linear-gradient(
      136.01deg,
      rgba(157, 78, 221, 0.9) 19.34%,
      rgba(251, 46, 134, 0.9) 95.03%
    );
    display: flex;
    justify-content: center;
    margin: auto;
    // &::after {
    //   content: '';
    //   position: absolute;
    //   top: 5%;
    //   left: -5%;
    //   right: 5%;
    //   bottom: -5%;
    //   // border-radius: 50%;
    //   background-color: #290a44;
    // }
    img,
    .ant-skeleton-image {
      width: 142px !important;
      height: 142px !important;
      object-fit: cover;
      z-index: 2;
      position: relative;
      transform: translate(2px, -6px);
      border-radius: 50%;
      transition: all 0.15s ease-out;
      background-color: #fff;
      border: 1px solid rgba(#000, 0.1);
    }
    .ant-skeleton-image {
      width: 142px;
      height: 142px;
      background-color: #fff;
    }
    svg {
      background-color: #fff;
      padding: 8px;
      width: 92px !important;
      height: 92px !important;
    }
  }

  p {
    margin-top: 14px;
    color: #290a44;
    text-align: center;
    font-size: 14px;
    transition: all 0.1s ease;
  }

  &:hover {
    img,
    .ant-skeleton-image {
      // width: 100%;
      // height: 100%;
      transform: translate(0px, -4px);
      box-shadow: 1px 3px 4px 0px rgb(251, 46, 135);
    }
    p {
      color: $secondary-app-color;
    }
  }
}
