@import '../../../scss/config';

.user-dashboard-page {
  .dashboard-cards-wrapper {
    margin-top: 22px;
    display: grid;
    gap: 12px;
    grid-template-columns: 1fr 1fr;

    @include mediaLg {
      gap: 22px;
      grid-template-columns: repeat(4, 1fr);
    }
  }
}
