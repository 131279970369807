@import '../../../../scss/config';

.user-wallet-transactions-table-wrapper {
  margin-top: 2rem;
  border: 1px solid rgba(#000, 0.1);
  border-radius: 0.5rem;
  .row-actions {
    display: flex;
    justify-content: center;
    button {
      width: 36px;
      height: 36px;
    }
  }
}
