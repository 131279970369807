@import '../../../scss/config';

.create-product-form {
  .names-wrapper {
    display: grid;
    gap: 18px;
    @include mediaLg {
      grid-template-columns: 1fr 1fr;
    }
  }

  .section-title {
    color: #290a44;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 8px;
  }
  .form-body {
    display: grid;
    gap: 22px;
    @include mediaLg {
      grid-template-columns: 1fr 1fr;
    }
    .section-title {
      margin-top: 22px;
    }
    .sub-section {
      .checkbox-label-input {
        border-bottom: 1px dashed rgba(#000, 0.2);
        margin-bottom: 22px;
        display: grid;
        grid-template-columns: 1.8fr auto;
        gap: 8px;
        align-items: center;
        label {
          font-size: 16px !important;
        }

        .ant-row {
          min-height: 48px;
          display: grid;
          align-items: center;
        }
        .ant-input-affix-wrapper {
          max-width: 122px;
        }
      }
      &.refund-section {
        background: #ffffff;
        border: 1px solid #eaebed;
        box-shadow: 0px 4px 8px rgba(171, 171, 171, 0.05);
        border-radius: 8px;
      }
    }
    .section-one,
    .section-two {
      display: grid;
      align-content: start;
    }

    .radios-wrap {
      .labels-wrap {
        justify-content: stretch !important;
        gap: 28px !important;
      }
      label {
        svg {
          width: 28px;
          height: 28px;
        }
      }
    }
  }
  .sub-section {
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid rgba(#000, 0.1);
    padding: 12px 14px;
    @include mediaLg {
      padding: 26px 32px;
    }
  }

  .video-logo-wrap {
    display: grid;
    @include mediaLg {
      grid-template-columns: auto 1fr;
    }
    gap: 22px;

    .img-delete-btn-warp {
      width: 135px;
      height: 135px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .logo-dropzone-wrap {
    order: 1;
    justify-self: center;
    @include mediaLg {
      order: 0;
    }
    .file-input-label {
      text-align: center;
    }
    .dropZone {
      border-radius: 50%;
      width: 140px;
      height: 143px;
      padding: 0;
    }

    .default-dropzone-img {
      width: 134px;
      height: 134px;
      border-radius: 50%;
    }
    .delete-img-btn {
      border-radius: 50%;
    }

    .product-images-dropzone {
      & > p {
        text-align: center;
      }
    }
  }
  .yes-no-btns-wrapper {
    display: flex;
    align-items: center;
    gap: 22px;

    .submit-btn {
      justify-self: start !important;
      margin-top: 0;
    }
    .cancel-btn {
      padding: 10px 32px;
      border-radius: 4px;
      height: auto !important;
      border: 0;
      outline: 0;
      min-height: 52px;
      display: flex;
      gap: 5px;
      align-items: center;
      font-size: 16px;
      background-color: rgba(157, 78, 221, 0.05);
      color: $main-app-color;
    }
  }

  .discount-fields-wrapper {
    border-top: 1px solid #ddd;
    padding-top: 18px;
  }
  .discount-images-wrap {
    padding-top: 22px;
  }
  .codes-radios-inputs-wrapper {
    .radios-wrap {
      .labels-wrap {
        margin-bottom: 18px;
        display: flex !important;
        grid-auto-flow: column;
        flex-wrap: wrap;
      }
    }

    .start-end-dates-wrap {
      display: flex;
      gap: 22px;
      align-items: center;
      flex-wrap: wrap;
      input {
        height: 42px !important;
      }
    }

    .ant-picker.ant-picker-borderless {
      background-color: #f1f2f3 !important;
    }
  }

  .code-input-field {
    max-width: 232px;
    .ant-input-prefix {
      order: 1 !important;
    }
  }

  .ant-checkbox {
    font-size: 28px !important;
    input,
    .ant-checkbox-inner {
      width: 28px !important;
      height: 28px !important;
      &::after {
        width: 8px;
        height: 16px;
        top: 43%;
        left: 23%;
      }
    }
  }
}
