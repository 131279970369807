@import '../../../scss/config';

.affiliate-statistics-modal {
  .ant-modal-body {
    margin: 42px 22px;
  }

  .modal-btns {
    .cancel-btn {
      margin-top: 42px;
      padding: 10px 32px;
      color: $main-app-color;
      height: auto;
      background: rgba(157, 78, 221, 0.1);
      border: 0;
      outline: 0;
    }
  }
}
