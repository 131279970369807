@import '../../../scss/config';

.user-aff-stats-page {
  // background-color: #fff;
  .page-body {
    margin-top: 16px;
    display: grid;
    place-items: center;
  }
}
