@import '../../../scss/config';

.merchent-settings-page {
  .page-main-wrapper {
    margin-top: 22px;
    display: grid;
    @include mediaLg {
      grid-template-columns: 250px 1fr;
    }
    gap: 32px;

    .page-navigation {
      display: flex;
      width: 100%;
      overflow-x: auto;
      @include custom-scrollbar;
      padding-bottom: 12px;
      @include mediaLg {
        display: grid;
        align-items: start;
        align-content: start;
        gap: 22px;
      }

      .settings-nav-route {
        display: flex;
        align-items: center;
        gap: 6px;
        padding: 12px 18px;
        border-radius: 10px;
        color: gray;
        white-space: nowrap;
        @include mediaLg {
          border-radius: 14px;
          padding: 16px 28px;
        }
        span {
          display: flex;
          align-items: center;
        }
        &.selected-nav {
          background-color: $main-app-color;
          color: #fff;
        }
      }
    }
  }
}
