@import '../../../../scss/config';

.most-canceled-products-page {
  .page-content {
    padding: 1rem;
    background-color: #fff;
    box-shadow: 0px 0px 25px 10px #f8f8fb;
    border-radius: 0.9rem;
    margin-top: 2rem;
  }
  .title-arrow-wrap {
    margin-bottom: 22px;
    display: flex;
    justify-content: start;
    align-items: start;
    gap: 8px;
    color: $main-app-color;
    .arch-title {
      color: $main-app-color;
      font-size: 22px;
      font-weight: bold;
    }
    button {
      background-color: transparent;
      border: 0;
      cursor: pointer;
      padding: 4px;
      svg {
        width: 32px;
        height: 32px;
      }
    }
  }

  .content-chart-wrapper {
    display: grid;
    align-content: start;
    gap: 1rem;
    @include mediaLg {
      grid-template-columns: 1fr 322px;
    }

    .chart-wrapper {
      width: 100%;
      direction: ltr;
      height: 292px;
      @include mediaLg {
        height: 322px;
      }
    }

    .recharts-default-legend {
      display: flex;
      flex-direction: column;
    }
  }

  .products-wrap {
    display: grid;
    gap: 16px;
    @include mediaMd {
      grid-template-columns: 1fr 1fr;
    }
    @include mediaLLg {
      grid-template-columns: repeat(2, 1fr);
    }
    @include mediaXlg {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}
