@import '../../scss/config';

.messages-wrapper {
  @include mediaLg {
    margin-right: 32px;
    padding: 32px;
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 32px;
  }
}
