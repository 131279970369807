@import '../../scss/config';

.custom-share-modal {
  max-width: 552px !important;
  .ant-modal-content .ant-modal-close .anticon {
    border: 0 !important;
    background-color: #c4c4c4 !important;
  }
  .ant-modal-content {
    box-shadow: 0px 0px 25px 10px rgba(#fff, 0.2);
  }
  .ant-modal-body {
    padding: 24px 52px;
    margin-top: 32px;
  }

  .links-wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 22px;
    align-items: center;
    justify-content: space-around;
  }

  .share-p,
  .copy-p {
    color: #290a44;
    font-size: 18px;
    margin-bottom: 14px;
  }

  .copy-p {
    margin-top: 38px;
  }

  .to-copy-text-box {
    display: grid;
    gap: 4px;
    margin-bottom: 18px;
    .box-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 4px;
      border-radius: 8px;
      border: 1px solid #c4c4c4;
      padding: 10px 18px;
      font-size: 18px;
      button {
        display: grid;
        place-items: center;
        cursor: pointer;
      }
      svg,
      img {
        width: 38px;
        height: 38px;
      }
    }
    button {
      border: 0;
      outline: 0;
      background-color: transparent;
    }
  }
}
