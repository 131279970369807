.accept-service-form {
  position: relative;
  .form-body {
    .accept-input {
      margin-bottom: 0;
    }
    .ant-row {
      margin-bottom: 0 !important;
      .ant-input {
        height: 38px !important;
        width: 200px;
        border-radius: 5px !important;
        font-size: 14px !important;
        &.ant-input-rtl {
          padding-left: 62px;
        }
        &.ant-input-ltr {
          padding-right: 62px;
        }
      }

      .ant-form-item-explain-error {
        font-size: 12px;
      }
    }
  }

  .submit-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    &.rtl {
      left: 3px;
    }
    &.ltr {
      right: 3px;
    }
    margin: 0 !important;
    margin-top: 0 !important;
    font-size: 12px;
    padding: 4px 12px !important;
    height: 32px !important;
    min-height: auto !important;
    min-width: auto !important;
    border-radius: 4px !important;
  }
}
