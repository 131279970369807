.merchent-product-statistics-page {
  .chart-wrapper {
    .ant-picker {
      margin: 0 22px 12px 22px;
      border-radius: 6px;
      input {
        width: 172px;
        height: 32px;
      }
    }
  }
}
