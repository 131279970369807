@import '../../scss/config';

.main-app-profile-dropdown {
  min-width: 252px !important;
  .ant-dropdown-menu {
    border-radius: 8px;
  }
  .ant-dropdown-menu-item {
    padding: 0 !important;
    margin: 0 5px;
    margin-bottom: 2px;
    border-radius: 8px;
    &:hover {
      background-color: lighten($main-app-color, 36%);
      .settings-link {
        border-bottom-color: lighten($main-app-color, 36%);
      }
    }
  }

  .settings-link {
    margin-top: 8px;
    padding: 10px 22px;
    margin-bottom: 2px;
    display: flex;
    align-items: center;
    gap: 12px;
    border-bottom: 1px solid rgba(#000, 0.1);
    .wallet {
      color: $main-app-color;
    }
    .profile-img {
      width: 58px;
      height: 58px;
      border-radius: 50%;
      overflow: hidden;
      svg {
        width: 98%;
        height: 98%;
      }
      // img {
      //   width: 58px;
      //   height: 58px;
      //   border: 2px solid $main-app-color;
      //   border-radius: 50%;
      // }
    }
  }
  .profile-label {
    color: $main-app-color;
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 8px;
    padding: 10px 22px !important;
    cursor: default;
  }

  .menu-link {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 16px;
    padding: 12px 22px;

    svg {
      width: 20px;
      height: 20px;
      color: #6d6d6d;
    }
  }
  .signout-btn {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 16px;
    color: #ed0006;
    padding: 10px 22px !important;
    // margin-bottom: 6px;
  }
}
