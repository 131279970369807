@import '../../../scss/config';

.product-card {
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid rgba(#000, 0.08);

  // max-width: 282px;
  // &.grid {
  // }

  .discount-countdown {
    margin-top: 3px;
    color: $secondary-text-color;
    span {
      letter-spacing: 1px;
    }
  }

  &.row {
    .product-card-content {
      display: grid;
      grid-template-columns: 33% 1fr;
      gap: 10px;
      @include mediaLg {
        grid-template-columns: 44% 1fr;
        gap: 22px;
      }
      .custom-img-wrap,
      .p-logo {
        height: auto;
        @include mediaLg {
          min-height: auto;
          height: 242px;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          @include mediaLg {
            height: 242px;
          }
        }
        svg {
          width: 92px;
          height: 84px;
        }
      }
      .card-data {
        display: grid;
        align-content: center;
      }
    }
  }
  &.slider-product {
    margin: 0 6px;
    border: 1px solid rgba(#000, 0.1);
    overflow: hidden;
  }
  .product-card-content {
    .p-logo,
    .custom-img-wrap {
      display: grid;
      align-items: center;
      min-height: 222px;
      gap: 18px;
      @include mediaXXlg {
        min-height: 272px;
      }
      img {
        width: 100%;
        border-radius: 8px;
        height: 100%;
        max-height: 222px;
        object-fit: cover;
        object-position: top center;
        @include mediaXXlg {
          max-height: 272px;
        }
        object-fit: cover;
        object-position: center;
      }

      svg {
        width: 92px;
        height: 84px;
      }
    }
    .card-data {
      padding: 4px 8px 8px 8px;
      @include mediaLg {
        padding: 4px 12px 12px 12px;
      }
    }

    .status {
      display: flex;
      align-items: center;
      gap: 4px;
      margin-top: 8px;
      margin-bottom: 2px;
    }
    .title-price-wrap {
      margin-bottom: 4px;
    }
    .cat-catFilter {
      color: $main-app-color;
      font-size: 14px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      display: block;
    }

    .store-name-location-wrap {
      display: flex;
      gap: 4px;
      align-items: center;
      color: $secondary-app-color;
      font-size: 14px;
      flex-wrap: wrap;
    }

    .price-actions-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .p-price {
        color: $main-app-color;
        text-transform: uppercase;
        font-size: 20px;
        font-weight: bold;
      }
      .actions-wrap {
        display: flex;
        gap: 8px;
        align-items: center;
        .action-btn-itself {
          background-color: transparent;
          display: grid;
          align-items: center;
          border: 0;
          outline: 0;
          cursor: pointer;
          svg {
            width: 24px;
          }
          // &.is-fav {
          // }
        }
      }
    }

    .currency-span {
      font-size: 65%;
      margin-inline-start: 2px;
    }

    .p-title {
      color: #290a44;
      font-weight: bold;
      font-size: 14px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      display: block;
      @include mediaLg {
        font-size: 16px;
      }
    }

    .p-location {
      color: #c4c4c4;
    }

    .stars-wrap {
      display: flex;
      align-items: center;
      gap: 8px;
      // .stars-itself {}
      .count-wrap {
        font-size: 18px;
        color: #c4c4c4;
        margin-bottom: 5px;
      }
    }

    .p-price {
      font-size: 18px;
    }

    .card-actions-wrap {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 4px;
      button,
      a {
        background-color: #fff;
        border: 1px solid #eaebed;
        border-radius: 4px;
        display: flex;
        align-items: center;
        gap: 4px;
        justify-content: center;
        cursor: pointer;
        transition: all 0.15s ease-out;
        padding: 8px 10px;
        font-size: 14px;
        &:hover {
          color: $main-app-color;
          background-color: lighten($main-app-color, 35%);
          svg {
            path {
              fill: $main-app-color;
            }
          }
        }
      }
      .delete-btn {
        border: 0;
        color: #ed0006;
      }
    }
  }

  .refund-title {
    font-size: 13px;
    color: #25d366;
  }

  position: relative;
  .discount-badge {
    background-color: #ff9ec8;
    padding: 5px 9px;
    border-radius: 8px;
    position: absolute;
    left: 4px;
    top: 4px;
    color: #f81376;
  }

  &.user-card {
    cursor: pointer;
  }
}
