.affiliate-share-link-modal {
  .modal-content {
    padding: 1rem;
    .modal-title {
      color: #290a44;
      font-size: 20px;
      margin-bottom: 22px;
    }
    .congrats-text {
      font-size: 16px;
      margin-bottom: 18px;
    }

    .to-copy-text-box {
      display: grid;
      gap: 4px;
      margin-bottom: 18px;
      .box-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 4px;
        border-radius: 8px;
        border: 1px solid #c4c4c4;
        padding: 10px 18px;
        font-size: 15px;
        button {
          display: grid;
          place-items: center;
          cursor: pointer;
        }
        svg,
        img {
          width: 38px;
          height: 38px;
        }
        .pdf-link {
          width: 100%;
          display: grid;
          justify-content: space-between;
          grid-template-columns: 1fr auto;
          gap: 22px;
          align-items: center;
          svg {
            height: 38px;
            width: 38px;
          }
        }
      }
      button {
        border: 0;
        outline: 0;
        background-color: transparent;
      }
    }
    .modal-btns {
      margin-top: 42px;
      display: flex;
      gap: 18px;
      align-items: center;
      button {
        border: 0;
        outline: 0;
        padding: 16px 42px;
        border-radius: 5px;
        height: auto;
      }
    }
  }
}
