@import '../../../../scss/config';

.order-report-replies-modal-root {
  .ant-modal-wrap {
    display: flex;
    align-items: center;
  }
  .order-report-replies-modal {
    .modal-content {
      padding: 0 22px;
    }
  }
}
