@import '../../scss/config';

.fixed-bottom-header {
  @include mediaLg {
    display: none;
  }
  display: grid;
  position: fixed;
  z-index: $app-header-z-index;
  bottom: 0;
  left: 0;
  right: 0;
  height: 52px;
  background-color: rgba(#fff, 0.9);
  box-shadow: 0 -12px 14px rgba(#000, 0.1);
  border-radius: 15px 15px 0 0;

  .custom-container {
    display: grid;
  }

  .links-list {
    display: grid;
    grid-template-columns: 17% 17% 1fr 17% 17%;
    justify-content: space-between;
    gap: 12px;
    .profile-placeholder {
      width: 72px;
    }
    .profile-menu-btn {
      border: 0;
      outline: 0;
      padding: 2px;
      left: 50%;
      cursor: pointer;
      transform: translate(-50%, calc(12px - 50%));
      background-color: #fff;
      width: 72px;
      height: 72px;
      border-radius: 50%;
      position: absolute;
      display: grid;
      place-items: center;

      .ant-avatar {
        display: grid;
        place-items: center;
      }
      img {
        width: 58px;
        height: 58px;
      }
      svg {
        width: 22px;
        height: 22px;
      }
    }

    .shared-nav-btn {
      border: 0;
      outline: 0;
      padding: 0;
      display: grid;
      place-items: center;
      background-color: transparent;
      padding: 0 2px;
      cursor: pointer;
      &:hover {
        svg {
          path {
            fill: $main-app-color;
          }
        }
      }
      &.notifications-btn {
        &:hover {
          svg {
            path {
              fill: transparent;
              stroke: $main-app-color;
            }
          }
        }
      }

      .notification-btn-wrap {
        display: flex;
        align-items: center;
      }
    }
  }
}

.bottom-header-modal-wrapper {
  background-color: rgba(#000, 0.8);
  .bottom-header-modal {
    top: 50% !important;
    transform: translateY(-50%);
    .ant-modal-close-x {
      display: none;
      .anticon {
        border-color: rgba(#000, 0.7) !important;
        svg {
          color: #000 !important;
        }
      }
    }

    .ant-modal-content {
      background-color: transparent !important;
      display: grid;
      align-items: center;
    }
    .ant-modal-body {
      background-color: transparent;
      padding: 16px;
      display: grid;
      align-items: center;
    }
  }
}
