@import '../../../scss/config';

.create-store-step-three {
  background-color: #fff;
  color: #6d6d6d;
  max-width: 662px;
  margin: auto;
  border-radius: 8px;
  text-align: center;
  padding: 62px 22px;

  .main-text {
    color: $main-app-color;
    font-weight: bold;
    font-size: 28px;
    margin-bottom: 18px;
  }
  .extra-text {
    font-size: 18px;
  }

  .stores-link {
    background-color: $main-app-color;
    color: #fff;
    padding: 8px 28px;
    border-radius: 4px;
    display: block;
    width: fit-content;
    margin: auto;
    margin-top: 18px;
    font-size: 18px;
  }
}
