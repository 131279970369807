@import '../../scss/config';

.signin-page {
  .signin-form-section {
    display: grid;
    justify-items: center;
    align-content: center;
    padding-top: 42px;
    min-height: 100vh;
  }

  .logo {
    margin-bottom: 32px;
    img {
      width: 222px;
    }
  }
  .copyrights {
    color: #c4c4c4;
    text-align: center;
  }
}
