@import '../../../scss/config';

.merchent-store-statistics-page {
  .chart-products-wrapper {
    display: grid;
    gap: 22px;
    @include mediaLg {
      gap: 32px;
      grid-template-columns: 65% 1fr;
    }

    .chart-wrapper {
    }

    .products-wrapper {
      display: grid;
      align-items: start;
      gap: 22px;
      .title-date-wrapper {
        display: flex;
        gap: 12px;
        justify-content: space-between;
        align-items: center;
        .wrapper-title {
          font-size: 16px;
        }

        .ant-picker {
          border-radius: 6px;
          input {
            width: 72px;
            height: 32px;
          }
        }
      }
      .ps-wrapper {
        display: grid;
        align-items: start;
        gap: 12px;
        padding: 18px;
        border: 1px solid #eaebed;
        box-shadow: 0px 4px 8px rgba(171, 171, 171, 0.05);
        border-radius: 8px;
        background-color: #fff;
      }
    }
  }
}
