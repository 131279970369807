@import '../../../scss/config';

.report-client-form {
  margin: 1rem;
  .amount-field {
    .ant-input-prefix {
      order: 2;
      color: $main-app-color;
    }
  }

  .submit-btn {
    display: block;
    margin: auto !important;
    margin-top: 32px !important;
  }
}
