@import '../../scss/config';

.choose-cart-modal {
  // .ant-modal-header {
  //   background-color: transparent;
  // }
  .ant-modal-body {
    padding: 18px 0;
  }
  img {
    max-width: 100%;
    margin: auto;
    display: block;
    margin-bottom: 22px;
  }
  .success-text {
    color: #474747;
    font-size: 20px;
    text-align: center;
  }

  .modal-btns-wrap {
    // border-top: 1px solid rgba(#000, 0.1);
    padding-top: 22px;
    margin-top: 32px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 22px;

    .modal-btn {
      border: 0;
      outline: 0;
      padding: 12px 28px;
      border-radius: 8px;
      cursor: pointer;

      // &.continue-btn {
      // }
      &.cart-btn {
        background-color: $main-app-color;
        color: #fff;
        display: flex;
        align-items: center;
        gap: 5px;
      }
    }
  }
}
