@import '../../scss/config';

.main-app-footer {
  background-color: #f9f4fd;
  background-color: #fbf2ff;
  padding: 4px 0;
  font-size: 18px;
  color: #6d6d6d;
  .footer-bottom-content {
    background-color: #fdfbfe;
    background-color: #fbf2ff;
    .content {
      display: grid;
      gap: 12px;
      min-height: 97px;
      align-items: center;
      @include mediaLg {
        grid-template-columns: 1fr auto;
      }
    }
    .footer-logo {
      img {
        // max-width: 100%;
        // max-width: 182px;
        max-height: 122px;
      }
    }
  }
  .footer-payment-methods {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    justify-content: center;
    align-items: center;
    img {
      width: 42px;
    }
  }

  .main-content {
    padding: 32px 0;
    display: grid;
    gap: 32px;
    @include mediaLg {
      gap: 8px;
      grid-template-columns: 3fr 1fr 1.4fr;
    }
    .cats-wrap {
      display: grid;
      @include mediaLg {
        grid-template-columns: repeat(3, 1fr);
      }
      gap: 12px;
    }

    .list-title {
      color: #290a44;
      text-transform: uppercase;
      margin-bottom: 10px;
      @include mediaLg {
        margin-bottom: 22px;
      }
    }

    .list-wrap {
      display: grid;
      align-content: start;
    }
    .list-itself {
      display: grid;
      gap: 18px;
      color: #6d6d6d;
      align-content: start;
      button {
        border: 0;
        outline: 0;
        background-color: transparent;
        padding: 0;
        text-align: start;
        cursor: pointer;
      }
      button,
      a {
        color: #6d6d6d;
        font-size: 15px;
      }

      &.contact-list-item {
        margin-top: 12px;
        p {
          display: flex;
          gap: 5px;
          align-items: center;
          font-size: 15px;
        }
      }
    }

    &.merchant-content t {
      display: flex;
    }
  }

  .stores-links {
    margin-top: 22px;
    .links {
      display: flex;
      flex-wrap: wrap;
      gap: 18px;
      max-width: 258px;

      img {
        width: 120px;
      }
    }
  }
  .m3roof-link {
    display: grid;
    gap: 12px;
    margin-top: 12px;
    max-width: 258px;
    justify-items: center;
    img {
      max-width: 100%;
    }
  }

  .merchant-footer-wrapper {
    border-bottom: 1px solid rgba(#000, 0.1);
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    display: grid;
    gap: 1rem;
    @include mediaLg {
      grid-template-columns: repeat(3, 1fr);
    }
    .list-itself,
    .contact-us-list {
      display: flex;
      flex-direction: column;
      gap: 12px;
      a {
        &:hover {
          color: $main-app-color;
        }
      }
      .contact-list-item {
        p {
          display: block;
        }
        svg {
          width: 16px;
          height: 16px;
          margin-inline-end: 3px;
        }
      }
    }
  }
}
