@import '../../../scss/config';

.my-address-card {
  max-width: 300px;
  min-height: 250px;
  border-radius: 8px;
  display: grid;
  background-color: #fff;
  padding: 18px 22px 14px 22px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(171, 171, 171, 0.05);
  border: 1px solid #eaebed;
  color: #6d6d6d;
  .card-name {
    color: #000;
    font-weight: bold;
  }

  .ant-switch-checked .ant-switch-handle {
    left: calc(100% - 20px - 2px);
  }
  .ant-switch-rtl.ant-switch-checked .ant-switch-handle {
    right: calc(100% - 20px - 2px);
  }
  .address-toggle {
    background-color: #25d366;
    border-radius: 4px;
    height: 24px;
    .ant-switch-handle {
      &::before {
        border-radius: 4px !important;
        width: 20px;
        height: 20px;
      }
    }
    &.not-active {
      background-color: gray;
    }
  }

  .c-c-wrap {
    display: flex;
    gap: 4px;
  }

  .card-action-btns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.8rem;
    flex-wrap: wrap;
  }

  .edit-delete-btns {
    display: flex;
    gap: 8px;
    button,
    a {
      background-color: transparent;
      display: flex;
      align-items: center;
      gap: 5px;
      border: 0;
      outline: 0;
      padding: 3px 6px;
      cursor: pointer;
    }

    .edit-btn {
      color: #172b85;
    }
    .delete-btn {
      color: #ed0006;
    }
  }

  &.new-card {
    background-color: #f6eefc;
    place-content: center;
    place-items: center;
    text-align: center;
    gap: 18px;
    color: $main-app-color;
  }
}
