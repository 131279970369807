@import '../../../scss/config';

.new-store-stepper-form {
  .stepper-step {
    background-color: #fff;
    border: 1px solid rgba(#000, 0.1);
    padding: 18px 14px;
    @include mediaLg {
      padding: 22px 42px;
    }
  }
  margin-bottom: 42px;
}
