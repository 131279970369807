@import '../../scss/config';

.success-card {
  background-color: #fff;
  border-radius: 22px;
  padding: 52px 72px;
  display: grid;
  place-items: center;
  text-align: center;
  width: 90%;
  max-width: 732px;
  margin: auto;
  margin-bottom: 18px;
  border: 1px solid rgba(#000, 0.1);
  img {
    margin-bottom: 42px;
    width: 220px;
  }
  h2 {
    color: $main-app-color;
    font-weight: bold;
    margin-bottom: 12px;
  }
  p {
    color: #979797;
    margin-bottom: 18px;
  }

  .action-btn {
    background-color: $main-app-color;
    color: #fff;
    padding: 10px 32px;
    border-radius: 8px;
    height: auto;
    font-size: 18px;
  }

  &.success-signup {
    .logo-wrapper {
      width: fit-content;
      margin: auto;
      margin-bottom: 52px;

      svg {
        max-width: 90%;
      }
    }
  }
}
