@import '../../scss/config';

.privacy-page {
  // background-color: #fff;
  .app-logo {
    display: block;
    margin: auto;
    margin-top: 52px;
    margin-bottom: 42px;
    width: fit-content;
  }

  .page-content {
    padding: 52px;
    background-color: #fff;
    box-shadow: 0px 0px 25px 10px #f8f8fb;
    border-radius: 16px;
    max-width: 1000px;
    margin: auto;
  }
  .title-arrow-wrap {
    margin-bottom: 32px;
    display: flex;
    justify-content: start;
    align-items: start;
    gap: 8px;
    color: $main-app-color;
    .arch-title {
      color: $main-app-color;
      font-size: 22px;
      font-weight: bold;
    }
    button {
      background-color: transparent;
      border: 0;
      cursor: pointer;
      padding: 4px;
      svg {
        width: 32px;
        height: 32px;
      }
    }
  }

  .refund-p {
    color: #6d6d6d;
    line-height: 2;
    white-space: pre-wrap;
  }
}
