@import '../../scss/config';

.arch-heading {
  // margin: 0 22px;
  width: fit-content;
  .arch-title {
    text-align: center;
    color: #290a44;
    font-size: 15px;
  }

  img {
    width: 72px;
  }
}
