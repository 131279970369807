@import '../../../scss/config';

.saloon-settings-form {
  max-width: 922px;
  background-color: #fff;
  border: 1px solid rgba(#000, 0.05);
  border-radius: 12px;
  padding: 14px 12px !important;
  @include mediaLg {
    padding: 32px !important;
  }

  .country-code-phone-wrap {
    margin-bottom: 12px;
    @include mediaLg {
      margin-bottom: 28px;
    }
  }

  .row-form-wrapper {
    display: grid;
    align-items: center;
    // gap: 6px;
    @include mediaLg {
      gap: 22px;
      grid-auto-flow: column;
    }
    &.three {
      @include mediaLg {
        grid-template-columns: 2fr 1fr 1fr;
      }
    }
  }

  .save-cancel-btns {
    margin-top: 32px;
    display: flex;
    align-items: center;
    gap: 32px;
  }
  .cancel-btn {
    border: 0;
    outline: 0;
    background-color: transparent;
    color: $main-app-color;
    font-size: 18px;
    padding: 6px 18px;
    cursor: pointer;
    border-radius: 4px;
    &:hover {
      background-color: rgba($main-app-color, 0.2);
    }
  }
  .submit-btn {
    justify-self: start;
    padding: 10px 52px;
    margin-top: 0 !important;
  }
}
