@import '../../scss/config';

.blogs-page {
  margin-bottom: 32px;
  .breadcrumb-title {
    margin-bottom: 32px;
  }

  .blogs-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(238px, 1fr));
    gap: 18px;
  }

  .main-page-wrap {
    display: grid;
    gap: 32px;
    @include mediaLg {
      grid-template-columns: 1fr auto;
    }
  }
  .filter-related-wrap {
    padding: 22px;
  }
  .side-wrap {
    min-width: 212px;
    max-width: 242px;
    order: -1;
    @include mediaLg {
      order: 0;
    }
    .main-app-search-form {
      // max-width: 800px;
      margin-bottom: 32px;
      label {
        display: flex;
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;
        background-color: transparent;
        padding: 4px 22px;
        border-radius: 4px;
        gap: 12px;
        border: 1px solid #999;
      }
      input {
        background-color: transparent;
        border: none !important;
        outline: none !important;
        color: $main-text-color;
        height: 42px;
        width: 100%;

        &::placeholder {
          color: $main-text-color;
        }
      }
      .icon-wrap {
        pointer-events: none;
        display: grid;
        place-items: center;
      }
    }

    .cats-wrap-title {
      h3 {
        color: #371056;
        font-size: 24px;
        margin-bottom: 18px;
      }
      .cats-wrap {
        display: flex;
        flex-wrap: wrap;
        gap: 12px;
      }
      .cat-link {
        display: flex;
        gap: 4px;
        align-items: center;
        padding: 8px 28px;
        border: 0;
        outline: 0;
        background-color: #fff;
        border: 1px solid #eee;
        border-radius: 4px;
        color: #6d6d6d;
        cursor: pointer;
        &.selected {
          background-color: $secondary-app-color;
          color: #fff;
        }
      }
    }
  }
}
