@import '../../../scss/config';

.user-statistics-card {
  margin-bottom: 22px;
  padding: 24px 23px 24px 20px;
  background-color: #fff;
  box-shadow: 0px 4px 16px rgba(196, 196, 196, 0.24);
  border-radius: 12px;
  .card-details-fees-wrapper {
    border-bottom: 1px solid #ece9f1;
    padding-bottom: 32px;
    display: grid;
    align-items: center;
    gap: 32px;
    @include mediaLg {
      grid-template-columns: auto 1px 1fr;
      gap: 42px;
    }

    .v-divider {
      width: 1px;
      height: 80%;
      margin-top: 10%;
      background-color: #ece9f1;
      display: none;
      @include mediaLg {
        display: block;
      }
    }

    .product-wrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 18px;
      align-items: center;

      .custom-image {
        width: 122px;
        height: 122px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 52px;
          height: 52px;
        }
      }
      .p-img-wrapper {
        img {
          width: 122px;
          height: 122px;
          border-radius: 10px;
          object-fit: cover;
          object-position: center;
        }
      }
      .p-data {
        .store-name {
          color: $secondary-app-color;
        }
        .p-name {
          color: #290a44;
          font-size: 18px;
          @include mediaLg {
            font-size: 20px;
          }
        }
      }

      .to-copy-text-box {
        margin-top: 8px;
        border-radius: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 12px;
        background-color: #fdfbfe;
        border: 1px solid #c4c4c4;
        padding: 8px 12px;
        .box-label {
          font-size: 13px;
          color: #aaaaaa;
          display: none;
          @include mediaLg {
            display: block;
          }
        }
        .box-content {
          font-size: 13px;
          display: flex;
          align-items: center;
          gap: 4px;
          justify-content: flex-start;
          @include mediaLg {
            justify-content: space-between;
          }
          span {
            max-width: 200px;
            @include mediaLg {
              max-width: 322px;
            }
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
          button {
            display: grid;
            place-items: center;
            cursor: pointer;
          }
          svg,
          img {
            width: 38px;
            height: 38px;
          }
        }
        button {
          border: 0;
          outline: 0;
          background-color: transparent;
        }
      }
    }
    .fees-wrapper {
      display: grid;
      justify-content: center;
      .img-data {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 18px;
        .value-label-wrap {
          color: #292d32;
          font-size: 15px;
          .fees-label {
            margin-bottom: 6px;
          }
          .fees-amount-label {
            display: grid;
            grid-auto-flow: column;
            justify-content: start;
            align-items: center;
            gap: 8px;
            .fees-amount {
              color: #008de0;
              font-size: 18px;
              font-weight: bold;
              @include mediaLg {
                font-size: 20px;
              }
            }
          }
        }
      }
      .status-wrapper {
        margin-top: 10px;
        text-align: center;
        .status-label {
          color: #aaaaaa;
        }
        .status-value {
          &.active {
            color: #25d366;
          }
          &.not-active {
            color: red;
          }
        }
      }
    }
  }

  .card-statistics {
    margin-top: 32px;
  }

  .boxs-wrap {
    display: grid;
    gap: 32px;
    @include mediaLg {
      grid-template-columns: repeat(3, 1fr);
    }
    .s-box {
      background-color: #fff;
      border: 1px solid;
      border-radius: 8px;
      display: grid;
      gap: 12px;
      place-items: center;
      padding: 32px 22px;
      .value-p {
        font-size: 20px;
        font-weight: bold;
      }
      &.time-of-use {
        color: #fb2e86;
        border-color: #fb2e86;
      }
      &.total-sales {
        border-color: #25d366;
        color: #25d366;
      }
      &.new-users {
        border-color: #9d4edd;
        color: #9d4edd;
      }
    }
  }
}
