@import '../../scss/config';

.cart-step-three {
  .step-content {
    background-color: #fff;
    max-width: 800px;
    margin: auto;
    display: grid;
    justify-items: center;
    padding: 92px;

    .success-img {
      max-width: 100%;
      margin-bottom: 22px;
    }
    .success-text {
      margin-bottom: 12px;
    }
    .my-orders-link {
      cursor: pointer;
      margin-top: 32px;
      background-color: $main-app-color;
      padding: 12px 62px;
      border-radius: 5px;
      color: #fff;
      outline: 0;
      border: 0;
    }
  }
}
