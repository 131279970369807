@import '../../../scss/config';

.user-order-card {
  background: #ffffff;
  border: 1px solid #eaebed;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px rgba(171, 171, 171, 0.05);
  border-radius: 8px;
  margin-bottom: 32px;
  overflow: hidden;
  padding-bottom: 18px;

  @include mediaLg {
    padding-bottom: 32px;
  }
  .label {
    color: #6d6d6d;
  }
  .card-header {
    background-color: #dfdfdf;
    align-items: center;
    padding: 14px 10px;
    display: grid;
    gap: 4px;
    @include mediaLg {
      padding: 14px 26px;
      display: grid;
      grid-template-columns: 1fr 1fr auto;
      gap: 42px;
    }

    .nums-method-wrap {
      display: flex;
      align-items: center;
      gap: 12px;
      flex-wrap: wrap;
    }

    .repay-link {
      background-color: #25d366;
      color: #fff;
      padding: 3px 16px;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: fit-content;
      font-size: 14px;
    }

    .num,
    .place-date-wrapper,
    .method {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 14px;

      .value {
        color: $main-app-color;
      }
    }

    .method {
      display: flex;
      flex-direction: row;
      img {
        height: 24px;
      }
    }

    .img-label-wrap {
      display: grid;
      justify-items: center;
      grid-auto-flow: column;
      gap: 4px;
      @include mediaLg {
        gap: 2px;
        grid-auto-flow: row;
      }
      img {
        border-radius: 5px;
      }
      span {
        color: $main-app-color;
        font-size: 13px;
      }
    }
  }
  .merchent-info-wrap {
    border-top: 1px solid #c4c4c4;
    display: grid;
    gap: 32px;
    margin: 18px;
    margin-top: 8px;
    padding-top: 8px;
    @include mediaLg {
      grid-template-columns: 1fr 1fr;
      gap: 32px;
      margin: 32px;
      margin-top: 22px;
      padding-top: 22px;
    }
    .title {
      color: #290a44;
      font-size: 24px;
      margin-bottom: 18px;
    }

    .data {
      display: grid;
      gap: 8px;
    }
    .label {
      display: flex;
      gap: 4px;
      svg {
        height: 24px;
      }
      @include mediaLg {
        align-items: center;
      }
    }
    .name,
    .phone,
    .address {
      display: flex;
      gap: 4px;
      @include mediaLg {
        align-items: center;
      }
      .value {
        color: #290a44;
        font-size: 16px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
      }
      &.phone {
        .value {
          direction: ltr;
        }
      }

      .map-span {
        color: $secondary-app-color;
        font-size: 12px;
        cursor: pointer;
        padding: 0 5px;
      }
    }
  }

  .order-products {
    margin-top: 18px;
    display: grid;
    gap: 32px;
    .product-wrap {
      display: grid;
      gap: 12px;
      border-bottom: 1px dashed #c4c4c4;
      padding-bottom: 22px;
      margin: 0 14px;
      @include mediaLg {
        margin: 0 18px;
        grid-template-columns: auto 1fr;
      }
      // &:last-of-type {
      //   border-bottom: 0;
      //   padding-bottom: 0;
      // }

      .img-order-status-wrapper {
        display: grid;
        gap: 12px;
        justify-content: center;
        @include mediaLg {
          justify-content: start;
        }
        .p-logo {
          img,
          svg {
            border-radius: 50%;
            object-fit: cover;
            object-position: center center;
            width: 142px;
            height: 142px;

            @include mediaLg {
              width: 122px;
              height: 122px;
              border-radius: 8px;
            }
          }
        }
      }

      .p-data {
        display: grid;
        gap: 18px;
        @include mediaLg {
          grid-template-columns: 2.7fr 3.4fr;
        }
        .side-data {
          display: grid;
          align-content: space-evenly;
          gap: 12px;
          border-top: 1px solid rgba(#000, 0.2);
          padding-top: 8px;
          @include mediaLg {
            border-top: 0;
            padding-top: 0;
          }
          .side-data-list {
            display: grid;
            grid-template-columns: 0.8fr 0.8fr 0.8fr 0.8fr;
            @include mediaLg {
              grid-template-columns: 0.8fr 0.8fr 0.8fr 0.8fr 1fr;
            }

            .inform-date-wrapper {
              grid-column: 1 / -1;
              margin-top: 8px;
              @include mediaLg {
                margin-top: 0;
                grid-column: 5 / 6;
              }
            }
          }

          .cancel-order-btn {
            border: 0;
            outline: 0;
            background-color: #ed0006;
            color: #fff;
            border-radius: 8px;
            height: auto;
            padding: 8px 32px;
            margin: 0 22px;
            cursor: pointer;
            font-size: 14px;
            justify-self: center;
            @include mediaLg {
              justify-self: end;
            }
            &:hover {
              background-color: lighten(#ed0006, 20%);
            }
          }
          .cancel-date {
            text-align: end;
            color: #6d6d6d;
            span {
              color: #ed0006;
            }
          }
        }

        .main-data {
          display: grid;
          text-align: center;
          @include mediaLg {
            text-align: start;
          }
          .p-name {
            line-height: 1.2;
            color: $main-app-color;
            font-size: 22px;
            text-transform: capitalize;
            margin-bottom: 4px;
          }
          .cat-filter-cat {
            margin-bottom: 6px;
          }
          .item-price {
            display: flex;
            gap: 4px;
            justify-content: center !important;
            @include mediaLg {
              justify-content: flex-start !important;
            }
          }
          .address-wrap {
            color: #290a44;
            font-size: 15px;
            p {
              display: flex;
              gap: 4px;
              align-items: center;
              justify-content: center;
              svg {
                width: 20px;
              }
              @include mediaLg {
                justify-content: flex-start;
              }
            }
          }
          .refund-wrap {
            font-size: 14px;
            color: #64022c;
            margin-top: 4px;
            svg {
              margin: 0 3px -2px 3px;
            }
          }
          .option-price,
          .item-price {
            font-size: 14px;
            justify-content: start;
            margin-bottom: 4px;
            .value {
              color: $main-app-color;
              span {
                padding: 0 3px;
              }
            }
          }
        }

        .quantity-data {
          display: grid;
          gap: 12px;
          align-content: center;
          justify-content: center;
          text-align: center;
          label {
            color: #6d6d6d;
          }
          .value {
            color: $main-app-color;
            display: flex;
            gap: 3px;
            text-align: center;
            margin: auto;
          }
        }
      }
      .total-data,
      .delivery-wrapper,
      .confirm-wrapper {
        display: grid;
        gap: 12px;
        align-content: center;
        text-align: center;
        justify-content: center;
        label {
          color: #6d6d6d;
        }
        .value {
          color: $main-app-color;
          display: flex;
          align-items: center;
          gap: 3px;
          text-align: center;
          margin: auto;
          span {
            margin-bottom: 4px;
          }
        }
      }
      .inform-date-wrapper {
        display: grid;
        gap: 12px;
        align-content: center;
        text-align: center;
        justify-content: center;
        label {
          color: #6d6d6d;
        }
        .value {
          color: $main-app-color;
          display: flex;
          gap: 3px;
          text-align: center;
          margin: auto;
          font-size: 14px;
        }
      }
    }
  }

  .card-footer {
    display: grid;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin: 0 18px;
    margin-top: 14px;
    @include mediaLg {
      margin: 0 32px;
      margin-top: 32px;
      justify-content: space-between;
      grid-auto-flow: column;
      gap: 8px;
    }

    .btns-wrap {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: start;
      gap: 22px;
      .receipt-btn {
        background-color: $main-app-color;
        color: #fff;
        padding: 10px 28px;
        border-radius: 4px;
        outline: 0;
        border: 0;
        display: flex;
        align-items: center;
        gap: 6px;
        cursor: pointer;
      }
      .chat-btn {
        background-color: #9d4edd1a;
        color: $main-app-color;
        padding: 10px 28px;
        border-radius: 4px;
        outline: 0;
        border: 0;
        display: flex;
        align-items: center;
        gap: 6px;
        cursor: pointer;
      }
    }

    .rate-wrap {
      .rate-order-btn {
        background-color: transparent;
        outline: 0;
        border: 1px solid #25d366;
        border-radius: 5px;
        color: #25d366;
        display: flex;
        gap: 4px;
        align-items: center;
        cursor: pointer;
        padding: 12px 24px;
        @include mediaLg {
          padding: 10px 38px;
        }

        img {
          height: 22px;
        }
      }

      svg {
        width: 24px;
        height: 24px;
      }

      .rate-stars-date-wrapper {
        display: grid;
        justify-items: center;
        gap: 5px;
        .rate-date {
          color: #6d6d6d;
          span {
            color: #25d366;
          }
        }
      }
    }
  }
}
