@import '../../scss/config';

.custom-stepper-wrap {
  display: grid;

  .stepper-btns {
    align-self: start;
    margin-top: 32px;
    padding: 22px 0;
    border-top: 1px dashed rgba(#000, 0.3);
    display: grid;
    grid-auto-flow: column;
    justify-content: start;
    grid-gap: 12px;
    button {
      // font-family: inherit;
      padding: 14px 28px;
      min-width: 128px;
      display: flex;
      gap: 8px;
      svg {
        height: 18px;
      }

      .MuiButton-label {
        display: flex;
        gap: 8px;
      }
    }

    &.ltr {
      button {
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }
}
