@import '../../../scss/config';

.merchant-coupon-card {
  margin-bottom: 22px;
  padding: 1.4rem 1rem;
  background-color: #fff;
  box-shadow: 0px 4px 16px rgba(196, 196, 196, 0.24);
  border-radius: 12px;
  position: relative;

  .edit-btn {
    position: absolute;
    inset-inline-end: 0.4rem;
    top: 0.4rem;
    outline: 0;
    border: 1px solid rgba(37, 37, 37, 0.15);
    display: flex;
    gap: 0.2rem;
    align-items: center;
    justify-content: center;
    width: 38px;
    height: 38px;
    border-radius: 0.5rem;
    padding: 2px;
    cursor: pointer;
    svg {
      height: 22px;
      width: 22px;
      color: $main-app-color;
    }
  }

  .amount-p {
    color: $main-app-color;
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
  }
  .store-name {
    text-align: center;
    font-size: 0.8rem;
  }
  .card-name {
    text-align: center;
    font-size: 1.2rem;
  }
  .exp-date {
    font-size: 0.8rem;
    text-align: center;
  }

  .is-use {
    margin-bottom: 0.2rem;
    font-size: 0.9rem;
    text-align: center;
    padding: 0.5rem 0.2rem;
    border-radius: 0.5rem;
    cursor: pointer;
    &.used {
      // background-color: rgb(252, 199, 199);
      color: rgb(249, 71, 71);
    }
    &.not-used {
      // background-color: rgb(197, 239, 197);
      color: rgb(7, 146, 7);
    }
  }

  .copy-btn {
    outline: 0;
    border: 1px solid rgba(37, 37, 37, 0.15);
    display: flex;
    gap: 0.2rem;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    border-radius: 0.5rem;
    padding: 0.2rem;
    padding-inline-start: 1rem;
    cursor: pointer;
    svg {
      height: 36px;
      width: 36px;
    }
  }
}
