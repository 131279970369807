@import '../../../scss/config';

.change-password-form {
  width: 100%;
  // min-width: 448px;
  display: grid;
  height: max-content;
  max-width: 532px;

  .form-header {
    margin-bottom: 12px;
    .form-logo {
      display: grid;
      padding: 32px;
      padding-bottom: 8px;
      place-items: center;
      img {
        max-width: 100%;
        height: 52px;
      }
    }
    p {
      text-align: center;
      color: #888;
      font-size: 16px;
      @include mediaLg {
        font-size: 20px;
      }
      margin-bottom: 22px;
      max-width: 80%;
      margin: auto;
      margin-bottom: 18px;

      &.main-title {
        color: $main-app-color;
        font-size: 28px;
        margin-bottom: 6px;
      }
      &.sub-title {
        margin-bottom: 4px;
      }
    }
    a {
      font-size: 18px;
      font-weight: bold;
      color: $main-app-color;
      text-decoration: underline;
    }
  }

  .form-body {
    padding: 12px 14px;
    display: grid;
    @include mediaLg {
      padding: 22px 42px;
    }

    .ant-form-item {
      @include mediaLg {
        display: grid;
      }
      input {
        max-width: 100%;
      }
    }

    .ant-input-affix-wrapper {
      border-radius: 4px;
      height: 48px;
      // background-color: #f5f5f5 !important;
      border: 0;
      box-shadow: none;
      // border-right: 9px solid #2e4652 !important;
      border: 1px solid #c4c4c4;
      // input {
      //   background-color: #f5f5f5 !important;
      // }
      span[class^='ant-input-prefix'] {
        svg {
          path {
            transition: all 0.15s ease-out;
          }
        }
      }

      &:hover {
        border-color: $main-app-color;
        span[class^='ant-input-prefix'] {
          svg {
            path {
              fill: $main-app-color;
            }
          }
        }
      }
    }
    .ant-input-affix-wrapper-focused {
      border-color: $main-app-color;
      span[class^='ant-input-prefix'] {
        svg {
          path {
            fill: $main-app-color;
          }
        }
      }
    }

    .login-password-field-wrap {
      position: relative;
      &.with-eye-icon {
        input {
          padding-left: 42px !important;
        }
      }
      .eye-icon-btn {
        position: absolute;
        left: 14px;
        top: 40%;
        cursor: pointer;
        padding: 4px;
        z-index: 1;
        img {
          height: 18px;
        }
      }
    }

    .submit-btn {
      margin-top: 12px;
      cursor: pointer;
      outline: 0;
      border: 0;
      color: #fff;
      height: 50px;
      border-radius: 8px;
      background-color: $main-app-color;
      margin-top: 0;
      padding: 8px 22px;
      @include mediaLg {
        justify-self: center;
        padding: 8px 48px;
      }
    }
  }
  .already-have-account {
    display: flex;
    gap: 6px;
    justify-content: center;
    margin: 0 12px;
    a {
      color: $main-app-color;
    }
  }

  .terms-checkbox-wrap {
    display: flex;
    align-items: flex-start;
    .ant-form-item-control-input {
      min-height: auto;
    }
    span {
      font-size: 14px !important;
    }

    a {
      color: $main-app-color;
      text-decoration: underline;
    }
  }

  .save-cancel-btns {
    margin-top: 32px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 12px;
    justify-content: space-between;
    @include mediaLg {
      gap: 32px;
    }
  }
  .cancel-btn {
    border: 0;
    outline: 0;
    background-color: transparent;
    color: $main-app-color;
    font-size: 18px;
    padding: 6px 18px;
    cursor: pointer;
    border-radius: 4px;
    &:hover {
      background-color: rgba($main-app-color, 0.2);
    }
  }
}
