@import '../../../scss/config';

.custom-shared-form.lefted-cart-offer-form {
  display: grid;
  .form-body {
    h3 {
      margin-bottom: 18px;
      color: $main-app-color;
      font-weight: bold;
    }
    .order-date-label {
      margin-top: 16px;
      .ant-picker {
        background-color: #fff !important;
        height: 62px;
        border-radius: 8px;
        border: 1px solid #c4c4c4 !important;
      }

      input {
        height: auto !important;
        background-color: transparent !important;
        &:focus {
          outline: 0;
          box-shadow: none;
        }
      }
      .ant-picker-focused {
        border-color: $main-app-color !important;
      }
    }
    .radios-wrap {
      border: 1px solid #eaebed;
      border-radius: 8px;
      padding: 14px;
      @include mediaLg {
        padding: 22px 32px;
      }
      .radios-group-title {
        font-size: 22px;
        margin-bottom: 0;
      }
      .discount-label {
        grid-auto-flow: row;
        justify-content: stretch;
        gap: 0;

        .radio-text-field-wrap {
          display: grid;
          justify-content: space-between;
          align-items: center;
          grid-auto-flow: column;
          min-height: 102px;
          .price-field {
            width: 122px;
            @include mediaLg {
              width: 170px;
            }
          }
        }
      }

      &.notification-types-wrap {
        .radios-group-title {
          margin-bottom: 18px;
        }
        margin-top: 32px;
        .discount-label {
          gap: 22px;
        }
      }
    }
    .error-p {
      margin-top: 2px;
      padding: 0 !important;
    }
  }
  .form-actions-btns {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 12px;
    margin-top: 32px;

    button {
      padding: 10px 32px;
      border-radius: 4px;
      height: auto !important;
      border: 0;
      outline: 0;
      min-height: 52px;
      display: flex;
      gap: 5px;
      align-items: center;
      font-size: 16px;
      &.submit-btn {
        padding: 10px 52px;
        margin-top: 0;
      }
      &.cancel-btn {
        background-color: rgba(157, 78, 221, 0.05);
        color: $main-app-color;
      }
    }
  }
}
