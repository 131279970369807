@import '../../../scss/config';

.user-messages-page {
  // background-color: #fff;
  .page-body {
    margin-top: 16px;
    display: grid;

    .user-messages-table {
      border: 1px solid rgba(#000, 0.1);
      border-radius: 0.5rem;
      overflow: hidden;
      .row-actions {
        display: flex;
        justify-content: center;
        button {
          width: 36px;
          height: 36px;
        }
      }
    }
  }
}
