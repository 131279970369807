@import '../../scss/config';

.custom-slick-slider {
  margin-top: 32px;
  direction: ltr !important;
  .slick-list {
    @include heroHeight;
  }
  .slide-wrap {
    display: grid;
    border-radius: 22px;
    overflow: hidden;
    .slide-img {
      width: 100%;
      object-fit: cover;
      border-radius: 22px !important;
    }
  }

  &.main-app-slick-slider {
    margin-top: 0;
    .slide-wrap {
      border-radius: 0;
      .slide-img {
        @include heroHeight;
        border-radius: 0 !important;
      }

      &::after {
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        pointer-events: none;
        background-image: linear-gradient(
          90deg,
          rgba(224, 170, 255, 0) 0%,
          rgba(199, 125, 255, 0.92) 100%
        );
      }

      .slide-text {
        position: absolute;
        z-index: 2;
        left: 0;
        width: 100%;
        top: 50%;
        transform: translateY(-50%);

        .content-wrap {
          padding: 8px 0;
          @include mediaLg {
            padding: 18px 62px;
          }
          display: grid;
          justify-content: start;
          .text-wrap {
            max-width: 582px;
            display: grid;
          }
          .provide-p {
            color: #fcecf9;
            font-size: 24px;
            @include mediaLg {
              font-size: 36px;
            }
            margin-bottom: 4px;
          }
          .slide-h2 {
            color: #fff;
            text-shadow: 0px 0px 7px #fcdbf6;
            font-size: 26px;
            @include mediaLg {
              font-size: 66px;
              line-height: 80px;
              margin-bottom: 28px;
            }
            font-weight: bold;
          }
          .sub-title {
            color: #fcecf9;
            font-size: 22px;
            margin-bottom: 14px;
            @include mediaLg {
              font-size: 32px;
              margin-bottom: 32px;
            }
          }
          .shop-link {
            justify-self: start;
            display: flex;
            background-color: $secondary-app-color;
            color: #fff;
            border-radius: 4px;
            padding: 8px 26px;
            font-size: 20px;
            font-weight: bold;
          }

          &.rtl {
            direction: rtl;
          }
          &.ltr {
            direction: ltr;
          }
        }
      }
    }

    .slick-prev,
    .slick-next {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      display: block;
      font-size: 16px;
      width: 52px;
      height: 52px;
      cursor: pointer;
      // border: 1px solid $main-app-color;
      // border-radius: 50%;
      outline: none;
      background: transparent;
      background-color: #fff;
      background-color: transparent;
      display: grid;
      place-items: center;
      z-index: 1;
      &::before {
        content: '';
      }
      .btn-content {
        position: relative;
        display: flex;
        place-content: center;
        align-items: center;
        svg {
          width: 32px;
          height: 32px;
        }
      }
      @include mediaXlg {
        width: 52px;
        height: 52px;
        .btn-content {
          svg {
            width: 42px;
            height: 42px;
          }
        }
      }
    }
    .slick-prev {
      left: -18px;
      left: 18px;
      right: auto;
      @include mediaXlg {
        left: -26px;
        left: 26px;
      }
    }
    .slick-next {
      // left: calc(12px + 52px);
      right: -18px;
      right: 18px;
      left: auto;
      @include mediaXlg {
        right: -26px;
        right: 26px;
      }
    }

    .slick-dots {
      bottom: 38px;
      li {
        width: 28px;
        height: 28px;
        button {
          width: 28px;
          height: 28px;
          padding: 0 !important;
          transform: rotate(45deg);
          display: grid;
          place-items: center;
          &::before {
            border: 3px solid #fff;
            opacity: 1;
            width: 22px;
            height: 22px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            content: '';
            border-radius: 4px;
          }
        }

        &.slick-active {
          button {
            &::before {
              background-color: $secondary-app-color;
              border-color: $secondary-app-color;
            }
          }
        }
      }
    }
  }
}
