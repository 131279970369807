@import '../../scss/config';

.forget-pass-digits-form {
  display: grid;
  .digits-wrap {
    display: grid;
    grid-template-columns: repeat(4, auto);
    justify-content: center;
    @include mediaLg {
      grid-template-columns: repeat(4, 82px);
    }
    gap: 8px;
    direction: ltr !important;
    input {
      padding-bottom: 6px;
      text-align: center;
      font-size: 18px;
      font-weight: bold;
      border-radius: 4px;
      border: 1px solid rgba(#000, 0.2);
      padding: 2px 4px;
      width: 44px;
      height: 42px;
      @include mediaLg {
        width: 58px;
        height: 52px;
      }
    }
  }

  .dont-btn-wrap {
    text-align: center;
    margin-top: 4px;
    span {
      color: #c4c4c4;
    }
    .resend-btn {
      background-color: transparent;
      color: $main-app-color;
      border: 0;
      outline: 0;
      cursor: pointer;
      padding: 4px;
    }
  }
  .submit-btn {
    justify-self: center;
    background-color: $main-app-color;
    color: #fff;
    border-radius: 10px;
    padding: 10px 18px;
    border: 0;
    outline: 0;
    min-height: 52px;
    min-width: 132px;
    max-width: 182px;
    margin-top: 32px;
  }
}
