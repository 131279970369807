@import '../../scss/config';

.home-cats-section {
  padding: 72px 0;

  .section-content {
    margin-top: 22px;
    .cats-list {
      display: flex;
      flex-wrap: wrap;
      gap: 32px;
      justify-content: center;
      .cat-item {
        display: grid;
        gap: 12px;
        justify-items: center;
        border: 0;
        outline: 0;
        background-color: transparent;
        cursor: pointer;
        .cat-img {
          width: 132px;
          height: 132px;
          border-radius: 50%;
          overflow: hidden;
          display: grid;
          place-items: center;
          background-color: #fff;
          background-color: $secondary-background-color;
          border: 2px solid rgba(#000, 0.1);
          transition: all 0.15s ease-out;
          img {
            width: 100%;
            object-fit: cover;
            object-position: center center;
          }
        }
        .cat-name {
          color: #6d6d6d;
          text-align: center;
          text-transform: uppercase;
          // font-size: 18px;
        }

        &:hover {
          .cat-img {
            border-color: $secondary-app-color;
            background-color: lighten($secondary-app-color, 37%);
          }
          .cat-name {
            color: $secondary-app-color;
          }
        }
      }
    }
  }
}
