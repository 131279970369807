@import '../../../scss/config';

.store-order-card {
  background: #ffffff;
  border: 1px solid #eaebed;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px rgba(171, 171, 171, 0.05);
  border-radius: 8px;
  margin-bottom: 32px;
  overflow: hidden;
  .label {
    color: #6d6d6d;
  }
  .card-header {
    background-color: #dfdfdf;
    align-items: center;
    padding: 14px 10px;
    display: grid;
    gap: 4px;
    @include mediaLg {
      padding: 16px 32px;
      display: grid;
      grid-template-columns: 1fr 1fr auto;
      gap: 42px;
    }

    .num,
    .place-date-wrapper,
    .method {
      display: flex;
      align-items: center;
      gap: 8px;
      .value {
        color: $main-app-color;
      }
    }

    .method {
      display: flex;
      flex-direction: row;
      img {
        height: 24px;
      }
    }

    .img-label-wrap {
      display: grid;
      justify-items: center;
      grid-auto-flow: column;
      gap: 4px;
      @include mediaLg {
        gap: 2px;
        grid-auto-flow: row;
      }
      span {
        color: $main-app-color;
        font-size: 14px;
      }
    }
  }

  .info-total-wrapper {
    display: grid;
    margin: 18px;
    margin-top: 8px;
    padding-top: 8px;
    @include mediaLg {
      grid-template-columns: 1fr 1fr;
      gap: 32px;
      margin: 32px;
      margin-top: 22px;
    }
  }

  .user-info-wrap {
    display: grid;

    .title {
      color: #290a44;
      font-size: 24px;
      margin-bottom: 18px;
    }

    .data {
      display: grid;
      gap: 8px;
    }
    .name-address,
    .phone-email {
      display: grid;
      gap: 8px;
      align-content: start;
    }

    .label {
      display: flex;
      //align-items: center;
      align-items: center;
      justify-content: center;
      gap: 4px;
      & > span {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      svg {
        width: 20px;
        height: 20px;
      }
    }

    .address {
      .value {
        display: flex;
        align-items: center;
      }
      .map-span {
        color: $secondary-app-color;
        font-size: 12px;
        cursor: pointer;
        padding: 0 5px;
      }
    }
    .name,
    .phone,
    .address,
    .email {
      display: flex;
      flex-wrap: wrap;
      @include mediaLg {
        align-items: center;
      }
      gap: 4px;
      .value {
        color: #290a44;
        font-size: 16px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
      }
    }
    &.phone {
      .value {
        direction: ltr;
      }
    }
  }

  .order-products {
    margin-top: 18px;
    display: grid;
    gap: 32px;
    .product-wrap {
      margin: 0 18px;
      display: grid;
      gap: 12px;
      border-bottom: 1px dashed #c4c4c4;
      padding-bottom: 22px;
      @include mediaLg {
        margin: 0 18px;
        grid-template-columns: auto 1fr;
      }
      &:last-of-type {
        border-bottom: 0;
        padding-bottom: 0;
      }

      .img-order-status-wrapper {
        display: grid;
        gap: 12px;
        justify-content: center;
        @include mediaLg {
          justify-content: start;
        }
      }

      .p-data {
        display: grid;
        gap: 18px;
        @include mediaLg {
          grid-template-columns: 2.7fr 3.4fr;
        }
        .side-data {
          display: grid;
          align-content: space-evenly;
          gap: 12px;
          border-top: 1px solid rgba(#000, 0.2);
          padding-top: 8px;
          @include mediaLg {
            border-top: 0;
            padding-top: 0;
          }
          .side-data-list {
            display: grid;
            grid-template-columns: 0.8fr 0.8fr 0.8fr;
            @include mediaLg {
              grid-template-columns: 0.8fr 0.8fr 0.8fr 1fr;
            }
          }

          .report-accept-reject-btns {
            display: flex;
            flex-wrap: wrap;
            gap: 18px;
            justify-content: space-between;
            justify-self: end;
            border-top: 1px dashed rgba(#000, 0.2);
            padding-top: 18px;
            button {
              height: max-content;
            }

            .accept-form-close-btn-wrapper {
              display: flex;
              align-items: center;
              .close-form-btn {
                background-color: transparent;
                outline: 0;
                border: 0;
                cursor: pointer;
                font-size: 20px;
                color: red;
                display: flex;
                align-items: center;
              }
            }
          }

          .cancel-date {
            text-align: end;
            color: #6d6d6d;
            span {
              color: #ed0006;
            }
          }

          .report-client {
            border: 0;
            outline: 0;
            color: #6e1210;
            border-radius: 8px;
            height: auto;
            padding: 8px 32px;
            cursor: pointer;
            font-size: 14px;
            background-color: #f8a2a0;
            transition: all 0.2s ease-out;

            &:hover {
              background-color: lighten(#f8a2a0, 6%);
              box-shadow: 0 2px 4px rgba(#000, 0.1);
            }
          }
          .accept-reject-btns {
            justify-self: end;
            display: flex;
            gap: 12px;
            align-items: center;

            .accept-service-btn,
            .reject-service-btn {
              border: 0;
              outline: 0;
              color: #fff;
              border-radius: 8px;
              height: auto;
              padding: 8px 32px;
              cursor: pointer;
              font-size: 14px;
              transition: all 0.2s ease-out;
            }
            .accept-service-btn {
              background-color: #25d366;
              &:hover {
                background-color: lighten(#25d366, 15%);
                box-shadow: 0 2px 4px rgba(#000, 0.1);
              }
            }
            .reject-service-btn {
              background-color: #ed0006;
              &:hover {
                background-color: lighten(#ed0006, 20%);
                box-shadow: 0 2px 4px rgba(#000, 0.1);
              }
            }
          }
        }
        .main-data {
          display: grid;
          .p-name {
            line-height: 1.2;
            color: $main-app-color;
            font-size: 22px;
            text-transform: capitalize;
            margin-bottom: 4px;
          }
          .cat-filter-cat {
            margin-bottom: 6px;
          }
          .item-price {
            display: flex;
            gap: 4px;
          }
          .address-wrap {
            color: #290a44;
            font-size: 15px;
            p {
              display: flex;
              gap: 4px;
              align-items: center;
            }
          }
          .refund-wrap {
            font-size: 14px;
            color: #64022c;
            margin-top: 4px;
            svg {
              margin: 0 3px -2px 3px;
            }
          }
          .option-price,
          .item-price {
            font-size: 14px;
            justify-content: start;
            margin-bottom: 4px;
            .value {
              color: $main-app-color;
              span {
                padding: 0 3px;
              }
            }
          }
        }

        .quantity-data {
          display: grid;
          gap: 12px;
          align-content: center;
          justify-content: center;
          text-align: center;
          label {
            color: #6d6d6d;
          }
          .value {
            color: $main-app-color;
            display: flex;
            gap: 3px;
            text-align: center;
            margin: auto;
          }
        }
      }
      .total-data,
      .delivery-wrapper {
        display: grid;
        gap: 12px;
        align-content: center;
        text-align: center;
        justify-content: center;
        label {
          color: #6d6d6d;
        }
        .value {
          color: $main-app-color;
          display: flex;
          align-items: center;
          gap: 3px;
          text-align: center;
          margin: auto;
          span {
            margin-bottom: 2px;
          }
        }
      }
      .inform-date-wrapper {
        display: grid;
        gap: 12px;
        align-content: center;
        text-align: center;
        justify-content: center;
        label {
          color: #6d6d6d;
        }
        .value {
          color: $main-app-color;
          display: flex;
          gap: 3px;
          text-align: center;
          margin: auto;
          font-size: 14px;
        }
      }
    }
  }

  .card-footer {
    background-color: rgba($main-app-color, 0.08);
    display: grid;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 18px;
    margin-top: 14px;
    @include mediaLg {
      grid-auto-flow: column;
      padding: 32px;
      justify-content: space-between;
      margin-top: 32px;
    }
    .receipt-btn {
      background-color: $main-app-color;
      color: #fff;
      padding: 10px 28px;
      border-radius: 4px;
      outline: 0;
      border: 0;
      display: flex;
      align-items: center;
      gap: 6px;
      cursor: pointer;
    }

    .rate-reports-wrapper {
      display: flex;
      gap: 1.4rem;
      align-items: center;

      .reports-btn {
        border: 0;
        outline: 0;
        border-radius: 8px;
        height: auto;
        padding: 8px 32px;
        cursor: pointer;
        font-size: 14px;
        transition: all 0.2s ease-out;
        background-color: #cfcfcf;

        &:hover {
          background-color: #ccc;
          box-shadow: 0 2px 4px rgba(#000, 0.1);
        }
      }

      .rate-wrap {
        display: grid;
        justify-items: center;

        p {
          color: #999;
          font-size: 13px;
          text-align: center;
        }
        .ant-rate-star-zero {
          svg {
            color: #ccc;
          }
        }
        svg {
          width: 24px;
          height: 24px;
          // fill: blue;
        }
      }
    }
  }
}
