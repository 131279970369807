@import '../../../scss/config';

.model-order-receipt-modal {
  .receipt-btn {
    background-color: $main-app-color;
    color: #fff;
    padding: 10px 28px;
    border-radius: 4px;
    outline: 0;
    border: 0;
    display: flex;
    align-items: center;
    gap: 6px;
    cursor: pointer;
    margin: 32px auto 12px auto;
  }
}
