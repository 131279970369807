@import '../../../scss/config';

.merchent-affiliate-page {
  .title-create-affiliate-btn-wrap {
    display: flex;
    gap: 22px;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 22px;

    .create-new-aff-btn {
      background-color: $main-app-color;
      color: #fff;
      display: flex;
      gap: 2px;
      align-items: center;
      border-radius: 4px;
      padding: 12px 34px;
      height: auto;
    }
  }

  .affiliate-table-wrap {
    .affiliate-table {
      .action-btns-wrap {
        display: flex;
        justify-content: flex-end;
        justify-self: end;
        .affiliate-action-btn {
          background-color: transparent;
          border: 0;
          outline: 0;
          padding: 6px 20px;
          border-radius: 4px;
          display: flex;
          gap: 4px;
          align-items: center;
          cursor: pointer;
          svg {
            width: 14px;
            height: 14px;
          }
          &.edit {
            background-color: #196c1036;
            color: #196c10;
          }
          &.copy {
            background-color: rgba(23, 43, 133, 0.1);
            color: #172b85;
          }
          &.share {
            background-color: #9d4edd1a;
            color: #9d4edd;
          }
          &.statistics {
            background-color: #6461fc1a;
            color: #6461fc;
          }
          &.delete {
            background-color: #ed00061a;
            color: #ed0006;
          }
        }

        .ant-switch-checked .ant-switch-handle {
          left: calc(100% - 20px - 2px);
        }

        .ant-switch-rtl.ant-switch-checked .ant-switch-handle {
          right: calc(100% - 20px - 2px);
        }
        .affiliate-toggle {
          background-color: #25d366;
          border-radius: 4px;
          height: 24px;
          margin: 0 32px;
          .ant-switch-handle {
            &::before {
              border-radius: 4px !important;
              width: 20px;
              height: 20px;
            }
          }
          &.not-active {
            background-color: gray;
          }
        }
      }
    }
  }
}

.create-affiliate-form {
  max-width: 632px;

  .submit-btn {
    margin-top: 42px;
    padding: 8px 52px;
    height: 56px;
  }
}
