@import '../../scss/config';

.selected-filter-wrapper {
  margin-bottom: 22px;
  display: flex;
  gap: 32px;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  .filtered-by-selected-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 22px;
  }
  .selected-cat-filteredby-wrapper {
    .results-cat-name-wrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      align-items: center;
      font-size: 18px;
    }
    .selected-cat-name {
      font-size: 18px;
      color: $main-app-color;
      text-transform: capitalize;
      margin-bottom: 8px;
      @include mediaLg {
        margin-bottom: 0;
      }
    }
  }
  .filtered-by-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 14px;
    .filtered-title {
      color: #290a44;
    }
    .filters-lists-wrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 24px;

      .single-filter-list {
        display: flex;
        flex-wrap: wrap;
        gap: 4px;
        align-items: center;
        font-weight: bold;
        .values-list-wrapper {
          display: flex;
          gap: 5px;
          align-items: center;
          flex-wrap: wrap;
          span {
            background-color: lighten($main-app-color, 37%);
            padding: 5px 10px;
            border-radius: 2px;
            font-size: 14px;
            display: flex;
            align-items: center;
            gap: 2px;
            white-space: nowrap;
            cursor: pointer;
          }
        }

        .from-to-wrap {
          background-color: lighten($main-app-color, 37%);
          padding: 5px 10px;
          border-radius: 2px;
          font-size: 14px;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          gap: 2px;
          cursor: pointer;
          white-space: nowrap;
        }
        .single-filter-list-label {
          color: #292c58;
          font-weight: normal;
        }
      }
    }
  }

  .view-as-sort-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: start;
    gap: 12px;
    @include mediaLg {
      gap: 32px;
    }

    .sort-filter-wrapper,
    .custom-view-as-wrapper {
      display: flex;
      gap: 8px;
      align-items: center;
    }
  }
}
