@import '../../../scss/config';

.store-products-page {
  .title-create-product-btn-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 22px;

    .new-product-btn {
      transition: all 0.2s ease-out;
      border: 1px solid $main-app-color;
      color: $main-app-color;
      background-color: lighten($main-app-color, 38%);
      border-radius: 8px;
      display: flex;
      align-items: center;
      gap: 6px;
      font-size: 18px;
      justify-content: space-between;
      padding: 9px 18px;
      @include mediaLg {
        padding: 9px 30px;
      }
      svg {
        width: 22px;
        height: 22px;
        path {
          fill: $main-app-color;
        }
      }
      &:hover {
        background-color: lighten($main-app-color, 38%);
      }
    }
  }

  .products-wrap {
    display: grid;
    gap: 16px;
    @include mediaMd {
      grid-template-columns: 1fr 1fr;
    }
    @include mediaLLg {
      grid-template-columns: repeat(3, 1fr);
    }
    @include mediaXlg {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}
