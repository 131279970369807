@import '../../../scss/config';

.merchant-models-page {
  background-color: #fafafa;
  padding-bottom: 52px;
  min-height: 100vh;
  .page-content-wrap {
    margin-top: 32px;
    display: grid;
    @include mediaLg {
      grid-template-columns: auto 1fr;
    }
    gap: 22px;
  }

  .shared-filter-list-wrapper {
    background-color: #fff;
    border-bottom: 1px solid #e4e4e4;

    .shared-filter-scrollable-wrapper {
      overflow-y: auto;
      max-height: 252px;
      @include custom-scrollbar;
      background-color: #fff;

      .scrollable-inner {
        background-color: #fff;
        padding: 8px;
        & > .ant-tree {
          overflow-x: hidden;
        }

        .btns-wrapper {
          display: grid;
          justify-content: start;
          gap: 8px;
          a {
            background-color: transparent;
            border: 0;
            outline: 0;
            cursor: pointer !important;
            text-align: start;

            &.active-btn,
            &:hover {
              color: $secondary-app-color;
            }
            &.active-rate-btn {
              border: 1px solid rgba(#000, 0.1);
              border-radius: 4px;
            }
          }
          svg {
            cursor: pointer;
          }
        }
      }

      &::-webkit-scrollbar {
        width: 5px;
        background-color: #fff;
      }

      &::-webkit-scrollbar-track {
        background-color: transparent;
        margin: 5px 0;
      }

      &::-webkit-scrollbar-thumb {
        transition: all 0.3s ease-out;
        background-color: inherit;
      }
      transition: all 0.3s ease-out;
      &:hover {
        background-color: $main-app-color;
      }
    }
  }
}
