@import '../../../scss/config';

.user-bank-account-form {
  max-width: 1000px;
  background-color: #fff;
  border: 1px solid #eaebed;
  box-shadow: 0px 4px 8px rgba(171, 171, 171, 0.05);
  border-radius: 8px;
  padding: 40px !important;

  .submit-btn {
    display: block;
    margin-top: 32px !important;
  }

  .form-body {
    display: grid;
    gap: 32px;
    @include mediaLg {
      grid-template-columns: 1fr 0.8fr;
    }
    .fields-wrapper {
      display: grid;
    }

    .file-input-wrapper {
      align-self: start;
    }
  }
}
