@import '../../scss/config';

.forget-password-page {
  min-height: 100vh;
  display: grid;
  place-items: center;
  .page-content-wrap {
    display: grid;
    place-items: center;
    margin: 32px auto;
    .app-logo {
      margin-bottom: 22px;
    }
    .page-form-wrap {
      background-color: #fff;
      border: 1px solid rgba(#000, 0.1);
      border-radius: 12px;
      padding: 22px 18px;
      width: 90%;
      max-width: 542px;
      @include mediaLg {
        padding: 42px 52px;
      }
    }
  }
  .lock-img-wrap {
    display: grid;
    justify-items: center;

    p {
      margin-top: 12px;
      color: #290a44;
    }
    .signin-link {
      justify-self: center;
      background-color: $main-app-color !important;
      color: #fff !important;
      border-radius: 10px;
      padding: 10px 28px;
      border: 0;
      outline: 0;
      margin-top: 32px;
      cursor: pointer !important;
      &:disabled {
        opacity: 0.5;
      }
    }
  }
  .extra-text {
    text-align: center;
    margin-bottom: 22px;
    .main-title {
      color: $main-app-color;
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 4px;
    }
    .sub-title {
      color: #c4c4c4;
    }
  }
}
