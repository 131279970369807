.merchent-new-store-page {
  .title-arrow-wrap {
    margin-bottom: 42px;
  }
  .custom-container {
    max-width: 992px;
  }
  .ant-steps {
    max-width: 792px;
    margin: auto;
    margin-bottom: 52px;
  }
  .create-store-steps-indicator {
    .ant-steps-item-process
      > .ant-steps-item-container
      > .ant-steps-item-content
      > .ant-steps-item-title::after {
      background-color: #aaaaaa;
    }

    .ant-steps-item-wait
      > .ant-steps-item-container
      > .ant-steps-item-content
      > .ant-steps-item-title::after {
      background-color: #aaaaaa;
    }
  }
}
