@import '../../../scss/config';
.merchent-stores-details-page {
  .store-cover-photo {
    min-height: 256px;
    background-size: cover;
    background-position: center center;
  }

  .logo-tab-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo-name-wrap {
      display: flex;
      align-items: center;
      gap: 12px;
      .logo-wrap {
        padding: 5px;
        transform: translateY(-38px);
        background-color: #f9f8f8;
        border-radius: 50%;
        img,
        svg {
          width: 146px;
          height: 146px;
          object-fit: cover;
          object-position: center center;
          border-radius: 50%;
        }
      }

      .name-income-wrap {
        .name-status {
          display: flex;
          gap: 8px;
          align-items: center;
          .name {
            color: #290a44;
            font-size: 24px;
            font-weight: bold;
          }
          .status {
            display: flex;
            align-items: center;
            gap: 4px;
          }
        }
        .income-wrap {
          display: flex;
          align-items: center;
          gap: 4px;
          span {
            &:first-of-type {
              font-size: 14px;
            }
            &:last-of-type {
              font-size: 20px;
              font-weight: bold;
              color: $main-app-color;
            }
          }
        }
      }
    }

    .action-btns {
      display: flex;
      gap: 8px;
      align-items: center;

      .edit-link {
        display: flex;
        align-items: center;
        gap: 4px;
        color: $main-app-color;
        border: 1px solid $main-app-color;
        padding: 6px 22px;
        border-radius: 4px;
        font-size: 15px;
        transition: all 0.2s ease-out;
        &:hover {
          background-color: lighten($main-app-color, 34%);
        }
      }
      .more-actions-btn {
        border: 0;
        outline: 0;
        cursor: pointer;
        background-color: transparent;
      }
    }
  }

  .desc-contact-info-wrap {
    display: grid;
    gap: 28px;
    @include mediaLg {
      grid-template-columns: 1fr auto;
    }
    .desc-wrap,
    .contact-info-wrap {
      background-color: #fff;
      border: 1px solid #eaebed;
      box-shadow: 0px 4px 8px rgba(171, 171, 171, 0.05);
      border-radius: 8px;
      color: #aaaaaa;
      // max-width: 842px;
      width: 100%;
      .title-wrap {
        color: #290a44;
        border-bottom: 1px solid #c4c4c4;
        font-size: 18px;
        font-weight: bold;
      }
    }
    .desc-wrap {
      padding: 18px 32px;
      .title-wrap {
        padding-bottom: 12px;
        margin-bottom: 16px;
      }
      .desc-data {
        line-height: 2;
      }
    }
    .contact-info-wrap {
      padding: 12px 24px;
      width: 288px;
      .title-wrap {
        padding-bottom: 12px;
        margin-bottom: 16px;
      }
      .icon-title-wrap {
        margin-bottom: 12px;
        display: flex;
        align-items: center;
        gap: 4px;
      }
    }
  }
  .ps-title {
    margin-top: 62px;
    color: #290a44;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 24px;
  }
  .products-wrap {
    display: grid;
    gap: 16px;
    @include mediaMd {
      grid-template-columns: 1fr 1fr;
    }
    @include mediaLLg {
      grid-template-columns: repeat(3, 1fr);
    }
    @include mediaXlg {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}
