@import '../../../scss/config';

.new-address-form {
  .form-body {
    background-color: #fff;
    border: 1px solid #eaebed;
    box-shadow: 0px 4px 8px rgba(171, 171, 171, 0.05);
    border-radius: 8px;
    display: grid;
    justify-content: space-between;
    gap: 0 32px;
    align-items: center;
    display: grid;
    gap: 22px;
    padding: 18px 14px !important;
    @include mediaLg {
      padding: 32px 42px !important;
      grid-template-columns: 0.7fr 1fr;
      gap: 32px;
    }
  }
  .submit-btn {
    justify-self: start;
    padding: 10px 52px;
    margin-top: 0 !important;
  }
  .save-cancel-btns {
    margin-top: 32px;
    display: flex;
    align-items: center;
    gap: 32px;
  }
  .cancel-btn {
    border: 0;
    outline: 0;
    background-color: transparent;
    color: $main-app-color;
    font-size: 18px;
    padding: 6px 18px;
    cursor: pointer;
    border-radius: 4px;
    &:hover {
      background-color: rgba($main-app-color, 0.2);
    }
  }
}
