@import '../../scss/config';

.popular-products-page {
  padding: 2rem 0;
  .title-wrap {
    margin-bottom: 1rem;
  }

  .products-wrap {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(292px, 1fr));
    gap: 18px;
  }
}
