.empty-component-wrap {
  display: grid;
  place-items: center;
  align-content: start;
  margin-top: 42px;
  gap: 32px;
  img,
  svg {
    max-width: 90%;
  }
}
