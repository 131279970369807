@import '../../../scss/config';

.shared-products-slider {
  margin-bottom: 62px !important;
  margin-top: 12px !important;
  // margin-top: 52px !important;
  // .slick-slide {
  //   width: fit-content !important;
  // }
  .slide-wrap {
    border-radius: 0 !important;
    // max-width: 282px;
  }
  .slick-list {
    height: auto;
  }

  .slick-prev,
  .slick-next {
    position: absolute;
    top: -32px !important;
    transform: translateY(-50%);
    display: block;
    font-size: 16px;
    width: 32px !important;
    height: 32px !important;
    @include mediaXlg {
      width: 40px !important;
      height: 40px !important;
    }
    cursor: pointer;
    // border: 1px solid $main-app-color;
    border-radius: 2px !important;
    outline: none;
    background: transparent;
    background-color: #e7d9f3 !important;
    display: grid;
    place-items: center;
    z-index: 1;
    &::before {
      content: '';
    }
    .btn-content {
      width: 100%;
      height: 100%;
      position: relative;
      display: grid !important;
      place-items: center !important;
      svg {
        width: 11px;
        height: 11px;
      }
    }
    @include mediaXlg {
      width: 40px;
      height: 40px;
      .btn-content {
        svg {
          width: 12px;
          height: 12px;
        }
      }
    }
  }

  .slick-dots {
    bottom: -22px !important;
    li {
      button {
        &::before {
          font-size: 14px;
          width: 16px;
          height: 16px;
          border: 1px solid $main-app-color;
          border-radius: 50%;
          line-height: 16px;
        }
      }
    }
  }
  &.rtl {
    // direction: rtl !important;
    .slide-wrap {
      text-align: right;
      direction: rtl;
      .content-wrap {
        justify-content: end;
        .shop-link {
          justify-self: end;
        }
      }
      &::after {
        background-image: linear-gradient(
          90deg,
          rgba(199, 125, 255, 0.92) 0%,
          rgba(224, 170, 255, 0) 100%
        );
      }
    }
  }
  &.rtl {
    .slick-prev {
      left: 12px !important;
      right: auto !important;
    }
    .slick-next {
      // left: calc(12px + 52px);
      left: 62px !important;
      right: auto !important;
    }
  }

  &.ltr {
    .slick-prev {
      right: 62px !important;
      left: auto !important;
    }
    .slick-next {
      // left: calc(12px + 52px);
      right: 12px !important;
      left: auto !important;
    }
  }
}
