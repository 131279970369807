@import '../../scss/config';

.loading-modal {
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  // background-color: rgba(#000, 0.5);
  background-color: rgba(110, 110, 110, 0.478);
  display: grid;
  align-content: center;
  .modal-wrap {
    // background-color: #fff;
    max-width: 522px;
    height: 212px;
    border-radius: 5px;
    margin: 32px auto;
    display: grid;
    place-items: center;
  }
  .link {
    display: grid;
    place-items: center;
    background-color: $main-app-color;
    color: #fff;
    border-radius: 10px;
    padding: 10px 18px;
    border: 0;
    outline: 0;
    min-height: 52px;
    min-width: 132px;
    max-width: 182px;
    justify-self: center;
    margin-top: 22px;
    &:hover {
      background-color: $main-app-color;
      color: #fff;
    }
  }

  .sucess-btns-wrap {
    display: flex;
    gap: 22px;
  }
}
