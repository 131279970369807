@import '../../../scss/config';

.store-lefted-cart-page {
  .antd-custom-table .ant-table-thead > tr > th {
    &:first-of-type {
      text-align: start;
    }
  }

  .lefted-cart-table {
    margin-top: 16px;
    background-color: #fff;
    padding: 8px;
    border: 1px solid #eaebed;
    box-shadow: 0px 4px 8px rgba(171, 171, 171, 0.05);
    border-radius: 8px;
  }

  .name-photo-wrap {
    gap: 12px;
    align-items: center;
    display: flex;

    .custom-image {
      background-color: transparent !important;
    }
    img,
    svg {
      width: 48px !important;
      height: 48px !important;
      border-radius: 50%;
      object-fit: cover;
      object-position: center center;
    }
    .name-phone {
      text-align: start;
      color: #6d6d6d;
      font-size: 13px;
      white-space: nowrap;
    }
  }

  .lefted-cart-row-btns {
    justify-content: center;
    .row-btn {
      border: 0;
      outline: 0;
      background-color: transparent;
      cursor: pointer;
      img {
        width: 42px;
        height: 42px;
      }
    }
  }
}
