@import '../../scss/config';

.home-page {
  .main-home-h1 {
    margin-bottom: 22px;
  }

  .success-card-container {
    min-height: 100vh;
    padding: 32px 0;
    display: grid;
    align-items: center;
    // place-content: center;
  }
}
