@import '../../../scss/config';

.merchent-settings-form {
  background-color: #fff;
  border: 1px solid #eaebed;
  box-shadow: 0px 4px 8px rgba(171, 171, 171, 0.05);
  border-radius: 8px;
  padding: 18px 14px !important;
  @include mediaLg {
    padding: 32px 42px !important;
  }
  .form-body {
    display: grid;
    gap: 0 18px;
    @include mediaLg {
      grid-template-columns: 1fr 1fr;
      justify-content: space-between;
      gap: 0 32px;
    }
    align-items: center;
    label,
    .label-span {
      color: #290a44 !important;
    }
    .birthday-date-label {
      margin-bottom: 18px;
      .ant-picker {
        background-color: #fff !important;
        height: 48px;
        border-radius: 4px;
        border: 1px solid #c4c4c4 !important;
      }

      input {
        height: auto !important;
        background-color: transparent !important;
        &:focus {
          outline: 0;
          box-shadow: none;
        }
      }
      .ant-picker-focused {
        border-color: $main-app-color !important;
      }

      .ant-picker-suffix {
        order: -1;
      }
    }

    .user-settings-img-wrap {
      position: relative;
      width: fit-content;
      order: -1;
      margin: 32px 32px 42px 32px;
      justify-self: center;
      @include mediaLg {
        order: 0;
        margin: 72px 32px 0 32px;
        justify-self: end;
      }
      .cloud-img-wrap {
        pointer-events: none;
        display: grid;
        place-items: center;
        width: 52px;
        height: 52px;
        bottom: -8px;
        @include mediaLg {
          width: 82px;
          height: 82px;
          bottom: -18px;
        }
        border-radius: 50%;
        background-color: #fff;
        border: 5px solid $secondary-app-color;
        position: absolute;
        right: 2px;
        z-index: 2;
        cursor: pointer;

        .cloud-img {
          width: 22px;
          height: 22px;
          @include mediaLg {
            width: 32px;
            height: 32px;
          }
        }
      }
      .borders-wrap {
        pointer-events: none;
        position: absolute;
        top: -16px;
        left: -16px;
        width: calc(100% + 32px);
        height: calc(100% + 32px);
        img {
          position: absolute;
          &.left-border {
            left: -3px;
            top: 50%;
            transform: translateY(-45%);
            height: 50%;
            @include mediaLg {
              left: -8px;
              height: 50%;
            }
          }
          &.top-border {
            left: 50%;
            transform: translateX(-50%);
            width: 72%;
            top: 0;
            @include mediaLg {
              top: -8px;
              width: 60%;
            }
          }
          &.bottom-border {
            bottom: 1px;
            right: -5px;
            height: 67%;
            @include mediaLg {
              bottom: -8px;
              right: -8px;
              height: 74%;
            }
          }
        }
      }
    }

    .username-field {
      padding-top: 0;
      padding-bottom: 0;
      &.ltr {
        padding-left: 0 !important;
        .ant-input-prefix {
          margin-right: 12px;
        }
      }
      &.rtl {
        padding-right: 0 !important;
        .ant-input-prefix {
          margin-left: 12px;
        }
      }

      .ant-input-prefix {
        padding: 6.5px 11px;
        background-color: #eaebed80;
      }
    }

    .user-settings-images-dropzone {
      // width: 252px;
      // height: 252px;

      .dropZone {
        border: 0;
        padding: 0;
        background-color: transparent;
      }
      .dropzone-default-imgs-ul .default-dropzone-img {
        width: 132px;
        height: 132px;
        @include mediaLg {
          width: 256px;
          height: 256px;
        }
        border-radius: 50%;
        border: 0;
        pointer-events: none;
      }
    }

    .save-cancel-btns {
      margin-top: 32px;
      display: flex;
      align-items: center;
      gap: 20px;
      @include mediaLg {
        gap: 32px;
      }
    }
    .cancel-btn {
      border: 0;
      outline: 0;
      background-color: transparent;
      color: $main-app-color;
      padding: 6px 12px;
      font-size: 16px;
      @include mediaLg {
        padding: 6px 18px;
        font-size: 18px;
      }
      cursor: pointer;
      border-radius: 4px;
      &:hover {
        background-color: rgba($main-app-color, 0.2);
      }
    }
    .submit-btn {
      justify-self: start;
      margin-top: 0;
      padding: 10px 22px;
      @include mediaLg {
        padding: 10px 52px;
      }
    }
  }
}
