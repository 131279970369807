@import '../../../scss/config';

.merchant-coupons-page {
  // background-color: #fff;
  padding-top: 1rem !important;

  .title-create-coupon-btn-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .create-coupon-btn {
      transition: all 0.2s ease-out;
      border: 1px solid $main-app-color;
      color: $main-app-color;
      background-color: lighten($main-app-color, 38%);
      padding: 9px 22px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      gap: 6px;
      font-size: 0.95rem;
      cursor: pointer;
      svg {
        width: 18px;
        height: 18px;
        path {
          fill: $main-app-color;
        }
      }
      &:hover {
        background-color: lighten($main-app-color, 32%);
      }
    }
  }
  .page-body {
    margin-top: 2rem;
    display: grid;

    .page-content-wrapper {
      .list-wrapper {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(192px, 1fr));
        gap: 1rem;
      }
    }
  }
}
