@import '../../../scss/config';

.store-orders-page {
  .filter-wrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 8px 32px;
    margin-top: 22px;
    @include mediaLg {
      gap: 32px;
    }
    .filter-link {
      padding-bottom: 4px;
      color: #c4c4c4;
      border-bottom: 2px solid transparent;
      position: relative;
      font-size: 14px;
      &.active-filter {
        color: $main-app-color;
        border-color: $main-app-color;
      }
      &::after {
        content: '';
        height: 14px;
        width: 1px;
        background-color: #aaa;
        position: absolute;
        top: 40%;
      }
      &.ltr {
        &::after {
          right: 0;
          transform: translate(15px, -50%);
        }
      }
      &.rtl {
        &::after {
          left: 0;
          transform: translate(-15px, -50%);
        }
      }
      &:last-of-type {
        &::after {
          display: none;
        }
      }
    }
  }

  .orders-ul {
    margin-top: 22px;
  }
}
