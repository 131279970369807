@import '../../scss/config';

.main-app-search-form {
  // max-width: 800px;
  padding-top: 8px;

  label {
    display: flex;
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    background-color: #fff;
    padding: 2px 12px;
    border-radius: 6px;
    gap: 12px;

    .btn-map-wrapper {
      display: flex;
      align-items: center;
      gap: 14px;
      .map-btn {
        border: 0;
        outline: 0;
        background-color: transparent;
        display: grid;
        place-items: center;
        cursor: pointer;
      }
    }

    .search-btn {
      padding: 2px 32px;
      height: 72px;
      @include mediaLg {
        height: 38px;
      }
      &.rtl {
        border-radius: 5px 2px 2px 5px;
        margin-left: -7px;
      }
      &.ltr {
        border-radius: 2px 5px 5px 2px;
        margin-right: -7px;
      }
    }
  }
  input {
    background-color: transparent;
    border: none !important;
    outline: none !important;
    color: $main-text-color;
    width: 100%;
    height: 74px;
    @include mediaLg {
      height: 40px;
    }

    &::placeholder {
      color: $main-text-color;
    }
  }
  .icon-wrap {
    pointer-events: none;
    display: grid;
    place-items: center;
  }
}
