@import '../../../scss/config';

.merchent-dashboard-card {
  background-color: #fff;
  border-radius: 12px;
  border: 1px solid #eaebed;
  box-shadow: 0px 4px 8px rgba(171, 171, 171, 0.05);
  border-radius: 8px;
  transition: all 0.2s ease-out;

  &:hover {
    border-color: $main-app-color;
    background-color: lighten($main-app-color, 35%);
    .icon-wrap {
      path {
        fill: $main-app-color;
      }
    }
  }

  .shared-card {
    padding: 42px 18px;
    display: grid;
    text-align: center;
    cursor: pointer;
    .card-title {
      font-size: 18px;
      font-weight: bold;
      margin-top: 18px;
    }
  }

  &.merchent {
    &:last-of-type {
      .shared-card {
        .card-title {
          color: #ed0006;
        }
        .icon-wrap {
          path {
            fill: #ed0006;
          }
        }
      }
    }
  }
}
