@import '../../scss/config';

.general-single-product-page {
  // background-color: #fff;
  min-height: 100vh;
  padding-bottom: 62px;

  .video-gallery {
    border-radius: 4px;
    width: 100%;
    object-fit: cover;
    object-position: top center;
    height: 45vh;

    @include mediaLg {
      height: 65vh;
    }
  }
  .video-thumbnail {
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top center;
    }
    &::after {
      background-image: url('../../assets/imgs/icons/play-video.png');
      background-size: 54% 54%;
      background-repeat: no-repeat;
      background-position: center center;
      background-color: #fb2e8666;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-blend-mode: multiply;
    }
  }
  .image-gallery-thumbnail {
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top center;
    }
    &::after {
      background-repeat: no-repeat;
      background-position: center center;
      background-color: #fb2e8666;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-blend-mode: multiply;
    }
  }
  .discount-badge {
    z-index: 1;
    background-color: #ff9ec8;
    padding: 4px 9px;
    border-radius: 5px;
    position: absolute;
    left: 4px;
    top: 4px;
    font-size: 14px;
    color: #c90e5e;
  }
  .discount-countdown-badge {
    z-index: 1;
    background-color: rgba(#000, 0.4);
    padding: 5px 12px;
    border-radius: 5px;
    position: absolute;
    left: 4px;
    top: 42px;
    font-size: 14px;
    letter-spacing: 0.5px;
    color: #fff;
  }
  .views-count-badge {
    z-index: 1;
    background-color: rgba(#000, 0.6);
    padding: 6px 12px;
    border-radius: 8px;
    position: absolute;
    right: 4px;
    top: 4px;
    color: #fff;
    display: flex;
    gap: 4px;
    align-items: center;
    visibility: hidden;
    opacity: 0;
    transition: all 0.2s ease-out;
  }
  .gallery-wrap {
    &:hover {
      .views-count-badge {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  .discount-countdown {
    color: $secondary-text-color;
    span {
      letter-spacing: 1px;
    }
  }

  .ingredients-guarantee-wrapper {
    font-size: 14px;
    color: $secondary-text-color;
  }

  .product-details-section {
    padding: 22px 0;
    @include mediaLg {
      padding: 32px 0;
    }

    .currency-span {
      font-size: 65%;
      margin-inline-start: 3px;
    }
  }
  .gallery-details-wrap {
    @include mediaLg {
      display: flex;
      flex-wrap: wrap;
      gap: 22px;
    }
    @include mediaXlg {
      gap: 42px;
    }

    .gallery-wrap {
      position: relative;
      flex: 1 1;
      .image-gallery-thumbnails-container {
        margin-top: 12px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        button {
          border: 2px solid transparent;
          width: 72px;
          height: 72px;
          border-radius: 8px;
          overflow: hidden;
          &:hover,
          &.active {
            border-color: $main-app-color;
          }
          img {
            // max-height: 62px;
            width: 72px;
            height: 72px !important;
            object-fit: cover;
            object-position: center center;
          }
        }

        .image-gallery-thumbnail-inner {
          height: fit-content;
        }
      }
      // background-color: #fff;
      align-self: flex-start;
    }
    .details-parent-wrap {
      flex: 1 1;
      // flex: 0.8 1;

      .cat-catFilter {
        color: $main-app-color;
        font-size: 14px;
      }

      .store-name-location-wrap {
        display: flex;
        gap: 4px;
        align-items: center;
        color: $secondary-app-color;
        font-size: 14px;
        margin-bottom: 6px;
      }

      .name-actions-wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        gap: 12px;
        margin-bottom: 8px;
        @include mediaLg {
          margin-bottom: 0;
        }
      }
      .action-btns-wrap {
        display: flex;
        gap: 8px;
        align-items: center;
        .action-btn-itself {
          background: #ffffff;
          box-shadow: 0px 0px 8px rgba(229, 229, 229, 0.6);
          border-radius: 3.2px;
          width: 64px;
          height: 64px;
          display: grid;
          place-items: center;
          border: 0;
          outline: 0;

          cursor: pointer;
          svg {
            width: 22px;
          }
        }
      }

      .price-instock-wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: start;
        gap: 6px;
        // margin-top: 8px;
        .p-price {
          color: $main-app-color;
          text-transform: uppercase;
          font-size: 32px;
          font-weight: bold;
          .old-price {
            font-size: 24px;
          }
        }
        .stock-label {
          align-self: end;
          line-height: 2;
          .in-stock {
            color: #25d366;
          }
          .out-of-stock {
            color: red;
          }
        }
      }

      .p-title {
        color: #290a44;
        font-size: 28px;
        @include mediaXlg {
          font-size: 34px;
        }
        font-weight: bold;
        text-transform: capitalize;
      }

      .p-location {
        color: #c4c4c4;
      }

      .stars-wrap {
        margin: 6px 0;
        display: flex;
        align-items: center;
        gap: 8px;
        // .stars-itself {}
        svg {
          width: 24px;
          height: 24px;
        }
        .count-wrap {
          font-size: 18px;
          color: #c4c4c4;
          margin-bottom: 5px;
        }
      }

      .card-actions-wrap {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 4px;
        button,
        a {
          background-color: #fff;
          padding: 8px 12px;
          border: 1px solid #eaebed;
          border-radius: 4px;
          display: flex;
          align-items: center;
          gap: 4px;
          justify-content: center;
          cursor: pointer;
          transition: all 0.15s ease-out;
          &:hover {
            color: $main-app-color;
            background-color: lighten($main-app-color, 35%);
            svg {
              path {
                fill: $main-app-color;
              }
            }
          }
        }
      }

      .product-desc {
        color: #6d6d6d;
        line-height: 1.8;
      }
    }
    .add-to-cart-form {
      .labels-wrap {
        color: #6d6d6d;
        margin-top: 22px;
        display: grid;
        grid-auto-flow: row !important;
        gap: 4px !important;
        .radio-input-label {
          cursor: pointer;
          border-radius: 5px;
          padding: 10px 6px;
          border-left: 5px solid transparent;
          svg {
            width: 20px;
            height: 20px;
          }
          &.rtl {
            border-left: 0;
            border-right: 5px solid transparent;
          }
          &.selected {
            background-color: rgba(157, 78, 221, 0.05);
            border-color: $main-app-color;
          }
        }
      }

      .counters-add-to-cart-btns {
        margin-top: 12px;
        display: flex;
        flex-wrap: wrap;
        gap: 6px;
        justify-content: space-between;
        align-items: center;
        .submit-btn {
          margin-top: 0;
          align-items: center;
          border-radius: 4px;
          padding: 6px 28px;
          height: 64px !important;
          p {
            font-size: 20px;
            font-weight: bold;
            display: flex;
            align-items: center;
            gap: 4px;
            text-transform: capitalize;
            svg {
              width: 22px;
              height: 22px;
            }
          }
          &:disabled {
            background-color: lighten($main-app-color, 15%) !important;
            color: #fff;
          }
        }
      }

      .counter-btns-wrap {
        display: flex;
        align-items: center;
        gap: 18px;
        .count-btn {
          border: 0;
          outline: 0;
          background-color: #f3eafb;
          border-radius: 6px;
          width: 58px;
          height: 58px;
          font-size: 24px;
          cursor: pointer;
          color: $main-app-color;
        }
        .count-value {
          font-size: 24px;
          min-width: 22px;
          text-align: center;
          color: $main-app-color;
        }
      }
    }

    .date-time-wrap {
      display: grid;
      flex-wrap: wrap;
      gap: 18px;
      margin-top: 14px;
      .custom-date-picker-wrap {
        display: grid;
      }
    }
    .order-time-label,
    .order-date-label {
      .ant-picker {
        background-color: #fff !important;
        height: 62px;
        border-radius: 8px;
        border: 1px solid #c4c4c4 !important;
      }

      input {
        height: auto !important;
        background-color: transparent !important;
        &:focus {
          outline: 0;
          box-shadow: none;
        }
      }
      .ant-picker-focused {
        border-color: $main-app-color !important;
      }
    }

    .title-learn-wrap {
      margin-top: 12px;
      display: flex;
      gap: 4px;
      align-items: center;
      .refund-title {
        color: #64022c;
        font-size: 16px;
        display: flex;
        align-items: center;
        gap: 3px;
        svg {
          margin-bottom: 2px;
        }
      }
      a {
        color: $secondary-app-color;
        display: flex;
        align-items: center;
        gap: 4px;
      }
    }

    .refund-learn-more-wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 8px;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 8px;
    }

    .remaining-p {
      margin-bottom: 22px;
      display: flex;
      align-items: center;
      gap: 4px;
      font-size: 14px;
      color: #6d6d6d;
    }

    .terms-btn {
      background-color: transparent;
      display: flex;
      margin-top: 8px;
      gap: 8px;
      align-items: center;
      border: 0;
      outline: 0;
      cursor: pointer;

      .learn-more-wrapper {
        color: $secondary-app-color;
        display: flex;
        align-items: center;
        gap: 4px;
      }
    }
  }
  .product-desc-wrap {
    // max-width: 1032px;
    // margin: auto;
    .desc-label {
      font-size: 20px;
      margin-bottom: 8px;
      color: #290a44;
      margin-inline-start: 6px;
    }
    .desc-content {
      padding: 22px 32px;
      background: #ffffff;
      // box-shadow: 0px 0px 8px 4px rgba(231, 230, 239, 0.8);
      border: 1px solid rgba(#000, 0.1);
      border-radius: 12px;
      line-height: 1.8;
    }
  }

  .related-products-section {
    margin-top: 32px;
    @include mediaLg {
      margin-top: 52px;
    }
  }
  //
  .img-badge-wrap {
    position: relative;
  }
  .product-details-fallback-img {
    svg {
      max-height: 500px !important;
    }
  }

  .saloonStoreLink {
    background-color: #f3eafb;
    color: #9d4edd;
    padding: 14px 28px;
    height: 60px;
    border-radius: 8px;
    margin-top: 18px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    transition: all 0.2s ease-out;
    &:hover {
      background-color: #e4d0f7;
    }
  }
}
