@import '../../../scss/config';

.address-map-modal {
  .ant-modal-body {
    padding: 14px !important;
    @include mediaLg {
      padding: 24px;
    }
  }
}
