@import '../../scss/config';

.all-saloons-page {
  // background-color: #fff;
  .custom-container {
    max-width: 1242px;
    margin: auto;
  }

  .saloons-wrap {
    background-color: #f7f8fa;
    border: 1px solid rgba(#000, 0.1);
    padding: 42px;
    border-radius: 12px;
    margin: auto;
    margin-bottom: 1rem;
    margin-top: 0;
    gap: 1.5rem 1rem;
    justify-content: center;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    @include mediaMd {
      grid-template-columns: repeat(3, 1fr);
    }
    @include mediaLg {
      grid-template-columns: repeat(6, 1fr);
    }
  }
  .saloons-pagination {
    padding: 0 42px;
  }

  .arch-header-filter-wrapper {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
}
