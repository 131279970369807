@import '../../../scss/config';

.user-addresses-page {
  .page-body {
    .my-addresses-ul {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(262px, 1fr));
      gap: 18px;
    }
  }
}
