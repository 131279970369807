@import '../../../scss/config';

.new-affiliate-form {
  .form-body {
    .affiliate-info-section {
      padding-top: 18px;
      margin-top: 14px;
      border-top: 2px solid #e7e6ef;
      margin-bottom: 22px;
    }
    .radios-wrap {
      background-color: #f4f4f4;
      border-radius: 8px;
      padding: 32px 22px;
      margin-bottom: 12px;
      margin-top: 22px;

      .radio-input-label {
        cursor: pointer;
        padding: 10px 26px;
        border-radius: 6px;
        color: #aaaaaa;
        &.selected {
          background-color: rgba($main-app-color, 0.16);
          color: $main-app-color !important;
        }
      }
    }

    .form-actions-btns {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 12px;
      margin-top: 32px;

      button {
        padding: 10px 32px;
        border-radius: 4px;
        height: auto !important;
        border: 0;
        outline: 0;
        min-height: 52px;
        display: flex;
        gap: 5px;
        align-items: center;
        font-size: 16px;
        &.submit-btn {
          padding: 10px 52px;
          margin-top: 0;
        }
        &.cancel-btn {
          background-color: rgba(157, 78, 221, 0.05);
          color: $main-app-color;
        }
      }
    }
  }
}
