@import '../../../scss/config';

.user-wallet-form {
  .amount-field {
    .ant-input-prefix {
      order: 2;
      color: $main-app-color;
    }
  }

  .form-body {
    .ant-input-affix-wrapper {
      border-radius: 8px !important;
      @include mediaLg {
        height: 72px !important;
        background-color: #fdfbfe !important;
      }
    }
  }

  .submit-btn {
    display: block;
    margin: auto !important;
    margin-top: 32px !important;
    padding: 12px 44px !important;
    @include mediaLg {
      padding: 12px 82px !important;
    }
    border-radius: 4px !important;
    background-color: #9d4edd !important;
  }
}
