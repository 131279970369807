@import '../../scss/config';

.user-saloons-page {
  padding: 2rem 0;
  .title-wrap {
    margin-bottom: 1rem;
  }

  .saloons-wrap {
    display: grid;
    gap: 18px;
    @include mediaMd {
      grid-template-columns: repeat(2, 1fr);
    }
    @include mediaLg {
      grid-template-columns: repeat(3, 1fr);
    }

    .general-store-card {
      background: #ffffff;
      border: 1px solid #eaebed;
      box-shadow: 0px 4px 8px rgba(171, 171, 171, 0.05);
      border-radius: 8px;
      overflow: hidden;
      height: calc(100% - 56px);

      .cover-img-wrap {
        height: 152px;
        overflow: hidden;
        .store-cover {
          height: 152px;
          overflow: hidden;
          img,
          svg {
            width: 100%;
            height: 152px;
            object-fit: cover;
            object-position: top center;
            box-shadow: 0px 4px 8px rgba(171, 171, 171, 0.2);
            border-radius: 8px;
          }
        }
      }

      .store-img {
        background-image: linear-gradient(
          125deg,
          #9d4edd 0%,
          #fc69a8 78.12%,
          #fb2e86 100%
        );
        width: 108px;
        height: 108px;
        border-radius: 50%;
        // margin: -56px auto 0 auto;
        margin: auto;
        transform: translateY(-56px);
        position: relative;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        svg,
        img {
          background-color: #fff;
          width: 106px;
          height: 106px;
          border-radius: 50%;
          object-fit: cover;
          object-position: center center;
          box-shadow: 0px 4px 8px rgba(171, 171, 171, 0.2);
        }
      }

      .tt-wrap {
        padding: 18px 22px;
        text-align: center;
        transform: translateY(-56px);
      }
      .store-name {
        text-align: center;
        color: #290a44;
        font-size: 22px;
        font-weight: bold;
        text-transform: capitalize;
        margin-top: 8px;
      }
      .store-location,
      .status {
        color: #aaaaaa;
        display: flex;
        gap: 5px;
        justify-content: center;
        svg {
          margin-top: 4px;
        }
      }
      .desc {
        color: #aaa;
        font-size: 15px;
        line-height: 1.8;
        margin-top: 12px;
      }
    }
  }
}
