@import '../../../scss/config';

.lefted-modal-wrapper {
  overflow-y: hidden;
  .ant-modal {
    top: 10vh;
  }

  .lefted-cart-offer-modal {
    .ant-modal-body {
      margin: 12px;
      margin-top: 18px;
      padding: 12px;
      padding-top: 0;

      max-height: calc(80vh - 62px);
      overflow-y: auto;
      @include custom-scrollbar;
    }
  }
}