@import '../../scss/config';

.breadcrumb-section {
  // margin-top: 32px;
  .breadcrumb-content {
    padding: 12px 14px;
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    display: grid;
    .ant-breadcrumb {
      position: relative;
      z-index: 1;

      .ant-breadcrumb-link a {
        color: $main-text-color;
        &:hover {
          color: #000;
        }
      }

      & > span {
        &:last-of-type {
          .ant-breadcrumb-link {
            color: $main-app-color;
          }
        }
      }
    }
  }
}
