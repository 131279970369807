.affiliate-delete-modal {
  .ant-modal-body {
    padding: 62px 24px;
    text-align: center;
    .modal-content {
      color: #ed0006;
    }
    .modal-btns {
      .submit-btn {
        background-color: #ed0006;
        color: #fff;
      }
    }
  }
}
