@import '../../../scss/config';

.merchent-other-orders-page {
  background-color: #f7f8fa;

  .title-create-store-btn-wrap {
    margin-bottom: 18px;
  }

  .other-orders-table {
    .service-name-photo-wrap {
      display: flex;
      gap: 8px;
      align-items: center;
      justify-content: center;
      .custom-image {
        width: max-content;
        // background-color: transparent;
        border-radius: 5px;
        width: 50px !important;
        height: 50px !important;
      }
      svg {
        width: 28px !important;
      }
      img {
        width: 48px !important;
        height: 48px !important;
        border-radius: 5px;
        object-fit: cover;
        object-position: center center;
      }
      .name-phone {
        text-align: start;
        color: #6d6d6d;
        font-size: 13px;
      }
    }

    .row-actions {
      justify-content: center;
    }
  }
}
